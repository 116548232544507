import { schemeBlues } from "d3-scale-chromatic";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Authentication from "./Authentication";
import Chart from "../charts/ChartContainer";
import GroupedBarChart from "../charts/GroupedBarChart";
import Treemap from "../charts/Treemap";
import WorldMap from "../charts/worldMap/WorldMap";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import GlobalFilters from "../components/GlobalFilters";
import InputTable from "../components/InputTable";
import ResultsTable from "../components/ResultsTable";
import Row from "../components/Row";
import config from "../config";
import { Attribution, findDataMatchingFilters, initializeFilters, updateFilters } from "../utils/filters";
import { useMountEffect } from "../utils/useMountEffect";
import LoadingSpinner from "./LoadingSpinner";

//TODO
// individual page
// sector page
// country page

export const filterByImpactCategory = (
    data: IData[],
    impactCategory: string,
) => {
    if (impactCategory === config.JOBS) {
        return data.filter(
            d =>
                d.ImpactIndicator === impactCategory &&
                d.SubIndicator === config.subcategories.ALL,
        );
    } else {
        return data.filter(d => d.ImpactIndicator === impactCategory);
    }
};

export const initialFilters = {
    ClientNameCode: ["All"],
    FiscalYear: [],
    ReportingYear: [],
    EconomicActivity: ["All"],
    Country: ["All"],
    SubRegionName: ["All"],
    IncomeClassification: ["All"],
    ClientType: ["All"],
    attribution: [Attribution.No.toString()],
    LeastDeveloped: []
};


const Portfolio = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [data, setData] = useState<IData[]>([]);
    const [filters, setFilters] = useState<IFilters>(initialFilters);
    const [selectedFilters, setSelectedFilters] = useState<IFilters>(initialFilters);
    
    const loggedIn = useSelector((state: ReduxState) => state.user.loggedIn);

    useMountEffect(() => {
        document.title = t("portfolio.title");
        initializeFilters()
            .then(initFilters => {
                setFilters(initFilters)
                const initSelectedFilters = { ...initFilters, FiscalYear: [initFilters.FiscalYear[0]], ReportingYear: [initFilters.ReportingYear[0]] };
                setSelectedFilters(initSelectedFilters);
            })
            .catch(e => console.error(e))
            .finally(() => setInitializing(false));
    });

    useEffect(() => {
        setLoading(true);
        findDataMatchingFilters(selectedFilters)
            .then(results => setData(results))
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }, [selectedFilters]);

    useEffect(() => {
        updateFilters(selectedFilters)
            .then(result => {
                setFilters(result);
            })
            .catch(e => console.error(e));

    }, [data, selectedFilters]);

    const impactCategory = useSelector(
        (state: ReduxState) => state.data.impactCategory,
    );

    const dataFiltered = useMemo(
        () => filterByImpactCategory(data, impactCategory),
        [data, impactCategory]
    );
    
    if ((loggedIn === null) || !loggedIn) {
        return <Authentication />
    }
    
    if (initializing) {
        return <LoadingSpinner />;
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            <Row>
                <div className="col-12 col-sm-3 col-md-2 border-r all-filters">
                    <h5>{t("portfolio.filters")}</h5>
                    <Filters
                        selectedFilters={selectedFilters}
                        filters={filters}
                        setFunction={setSelectedFilters}
                        showYear={true}
                        showSingleInvestment={true}
                        selection="portfolio"
                    />
                </div>
                <div className="col-12 col-sm-9 col-md-10 portfolio">
                    <div className="row tables-row portfolio comparison-row">
                        <div className="col col-12 col-md-4 border-r input-data-table">
                            <h5>{t("portfolio.input")}</h5>
                            <InputTable
                                data={data}
                                attribution={filters.attribution[0]}
                            />
                        </div>
                        <div className="col col-12 col-md-8 overflow-x-auto">
                            <h5>{t("portfolio.results")}</h5>
                            <ResultsTable data={data} />
                        </div>
                    </div>
                    <Row classes={"global-filters-row"}>
                        <GlobalFilters />
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <div className="svg-container col-12">
                                <Chart
                                    title="Breakdown by country"
                                    download={true}
                                    fileName="JIM - Breakdown by country.svg"
                                >
                                    <WorldMap
                                        data={dataFiltered}
                                        width={590}
                                        impactCategory={impactCategory}
                                    />
                                </Chart>
                            </div>
                            <div className="svg-container col-12">
                                <Chart
                                    title="Breakdown by impact type"
                                    download={true}
                                    fileName="JIM - Breakdown by impact type.svg"
                                >
                                    <GroupedBarChart
                                        data={[dataFiltered]}
                                        height={250}
                                        width={500}
                                        impactCategory={impactCategory}
                                        labels={[""]}
                                        keys={[
                                            config.subtypes.DIRECT,
                                            config.subtypes.INDIRECT,
                                            config.subtypes.INDUCED,
                                            config.typesImpact.POWER,
                                            config.typesImpact.FE,
                                        ]}
                                    />
                                </Chart>
                            </div>
                        </div>

                        <div className="svg-container col-12 col-md-6">
                            <Chart
                                title="Breakdown by sector"
                                download={true}
                                fileName="JIM - Breakdown by sector.svg"
                            >
                                <Treemap
                                    data={dataFiltered}
                                    height={400}
                                    width={350}
                                    impactCategory={impactCategory}
                                    colorScheme={schemeBlues[9]}
                                />
                            </Chart>
                        </div>
                    </Row>
                </div>
            </Row>
            <Footer />
        </>
    );
};

export default Portfolio;
