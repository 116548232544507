import config from "../config";
import { Attribution } from "./filters";

/**
 * Reducer to return total sum for property `name` in array of objects.
 * @param name name of property to add up
 */
export const reduceTotal = (name = config.TOTAL) => (acc: any, curr: any) => {
    return acc + (curr[name] ? curr[name] : 0);
}


// maps Attribution enum to column names in data

export const getAttributionShareValue = (data: IData, attrType: string) => {
    let attributionShare: number | undefined | null = 1;
    switch (attrType) {
        case Attribution.Com:
            attributionShare = data.AttributionShareCommitted;
            break;
        case Attribution.Out:
            attributionShare = data.AttributionShareOutstanding;
            break;
        default:
            break;
    }
    if (attributionShare === undefined || attributionShare === null) {
        attributionShare = 0;
    }
    return attributionShare;
};

export const reduceWithAttribution = (attrType: string) => (
    acc: number,
    curr: IData,
) => acc + curr.Revenue * getAttributionShareValue(curr, attrType);
