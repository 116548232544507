import { max, sum } from "d3-array";
import { map, nest } from "d3-collection";
import { geoMercator, geoPath } from "d3-geo";
import { scaleSequential } from "d3-scale";
import {
    interpolateBlues,
    interpolateGreens,
    interpolateOranges,
} from "d3-scale-chromatic";
import { mouse, select, Selection } from "d3-selection";
import d3tip from "d3-tip";
import { Feature } from "geojson";
import { History } from "history";
import { alpha3ToNumeric } from "i18n-iso-countries";
import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import { feature } from "topojson-client";
import { GeometryCollection } from "topojson-specification";
import config from "../../config";
import boolToNumber from "../../utils/boolToNumber";
import countries from "../../utils/countries";
import { formatters } from "../../utils/formatters";
import { unique } from "../../utils/makeOptions";
import { difference, intersection, noIntersection } from "../../utils/sets";
import LegendBar from "./LegendBar";
import {
    LinearGradient1,
    LinearGradient2,
    LinearGradient3,
} from "./LinearGradients";
import worldDataJSON from "./world110m_no_antarctica.json";

interface MapValue {
    direct: number;
    indirect: number;
    induced: number;
    total: number;
    [propsName: string]: number;
}

export const groupByCountry = (data: IData[]) =>
    nest()
        .key((d: any) => String(alpha3ToNumeric(d[config.COUNTRY])))
        .rollup((l): any => {
            return {
                direct: sum(
                    (l as IData[]).filter(
                        f =>
                            (f[config.TYPE_IMPACT] ===
                                config.typesImpact.BACK_PERM ||
                                f[config.TYPE_IMPACT] ===
                                config.typesImpact.BACK_TEMP) &&
                            f.SubScope === config.subtypes.DIRECT,
                    ),
                    (d: IData) => d[config.TOTAL],
                ),
                indirect: sum(
                    (l as IData[]).filter(
                        f =>
                            (f[config.TYPE_IMPACT] ===
                                config.typesImpact.BACK_PERM ||
                                f[config.TYPE_IMPACT] ===
                                config.typesImpact.BACK_TEMP) &&
                            f.SubScope === config.subtypes.INDIRECT,
                    ),
                    (d: IData) => d[config.TOTAL],
                ),
                induced: sum(
                    (l as IData[]).filter(
                        f =>
                            (f[config.TYPE_IMPACT] ===
                                config.typesImpact.BACK_PERM ||
                                f[config.TYPE_IMPACT] ===
                                config.typesImpact.BACK_TEMP) &&
                            f.SubScope === config.subtypes.INDUCED,
                    ),
                    (d: IData) => d[config.TOTAL],
                ),
                power: sum(
                    (l as IData[]).filter(
                        f => f[config.TYPE_IMPACT] === config.typesImpact.POWER,
                    ),
                    (d: IData) => d[config.TOTAL],
                ),
                financeEnabling: sum(
                    (l as IData[]).filter(
                        f => f[config.TYPE_IMPACT] === config.typesImpact.FE,
                    ),
                    (d: IData) => d[config.TOTAL],
                ),
                total: sum(l as IData[], (d: IData) => d[config.TOTAL]),
            };
        })
        .entries(data);

export const findMaxTotalAmount = (data: any): number =>
    max(data, (d: any) => d.value.total);

// merge investment data and world map data
const prepareData = (data: IData[], impactCategory: string) => {
    const worldData = feature(
        worldDataJSON as any,
        worldDataJSON.objects.countries as GeometryCollection,
    ).features;
    const dataByCountry = groupByCountry(data);
    const countryMap = map(dataByCountry, d => d.key);
    worldData.forEach((d: any) => (d.total = countryMap.get(d.id)));
    worldData.forEach((d: any) => (d.impactCategory = impactCategory));
    const maxInv = findMaxTotalAmount(dataByCountry);
    return { worldData: worldData as FeatureExt[], maxInv: maxInv };
};

interface FeatureExt extends Feature {
    total:
    | {
        value: MapValue;
        value2?: MapValue;
    }
    | undefined;
    impactCategory?: string;
    [propsName: string]: unknown;
}

export const mergeSelectionsMap = (data1: FeatureExt[], data2: FeatureExt[]) => {
    return data1.map((d, i) => {
        if (d.total === undefined) {
            if (data2[i].total !== undefined) {
                return data2[i];
            }
            else {
                return d;
            }
        }
        else if (data2[i].total !== undefined) {
            return {
                ...d,
                total: {
                    value: d.total.value,
                    value2: data2[i].total!.value,
                },
            }
        }
        else {
            return d;
        }
    });
}
// makes html string to display tooltip when hovering country in map
const makeString = (value: MapValue, impactCategory: string) => `
        <table>
            <tbody>
                <tr>
                    <th>${config.subtypes.DIRECT}</th>
                    <td>${formatters[impactCategory](value.direct)}</td>
                </tr>
                <tr>
                    <th>${config.subtypes.INDIRECT}</th>
                    <td>${formatters[impactCategory](value.indirect)}</td>
                </tr>
                ${impactCategory === config.VA
        ? ""
        : `<tr>
                        <th>${config.subtypes.INDUCED}</th>
                        <td>${formatters[impactCategory](value.induced)}</td>
                    </tr>`
    }
                <tr>
                    <th>${config.typesImpact.POWER}</th>
                    <td>${formatters[impactCategory](value.power)}</td>
                </tr>
                <tr>
                    <th>${config.typesImpact.FE}</th>
                    <td>${formatters[impactCategory](
        value.financeEnabling,
    )}</td>
                </tr>
            </tbody>
        </table>
        `;

const tip = d3tip()
    .attr("class", "d3-tip")
    .html((data : { feature: FeatureExt, label: string, label2: string }) => {
        const feature = data.feature;
        const country = countries.getName(feature.id, "en");

        const heading1 = feature.total!.value2 !== undefined ? "<br>" + data.label : "";
        const value1 = makeString(feature.total!.value, feature.impactCategory!);

        const headingAndValue2 = feature.total!.value2 !== undefined
            ? "".concat(
                "<br>" + data.label2,
                makeString(feature.total!.value2, feature.impactCategory!)
            )
            : "";
        return `<b>${country}</b>${heading1}${value1}<br>${headingAndValue2}`;
    });

interface WorldMapProps {
    width: number;
    data?: IData[];
    label?: string
    data2?: IData[];
    label2?: string;
    history: History;
    multipleData?: boolean;
    impactCategory: string;
}

interface WorldMapState {
    maxInv1: number;
    maxInv2: number;
    maxInv3: number;
    show1: boolean;
    show2: boolean;
    show3: boolean;
}

interface WorldMapData {
    worldData: FeatureExt[];
    maxInv: number;
}

const widthHeightRatio = 2;

class WorldMap extends React.Component<WorldMapProps, WorldMapState> {
    ref: React.RefObject<any>;
    refCursor: React.RefObject<any>;
    projection: any;
    fill: any;

    public static defaultProps = {
        multipleData: false,
    };

    constructor(props: WorldMapProps) {
        super(props);
        this.state = {
            maxInv1: 0,
            maxInv2: 0,
            maxInv3: 0,
            show1: true,
            show2: false,
            show3: false,
        };
        this.ref = createRef();
        this.refCursor = createRef();
        this.projection = geoMercator().scale(90);
    }
    //TODO voronoi of the countries with investments

    updateFill(maxVal: number, color: (t: number) => unknown) {
        this.fill = scaleSequential(color).domain([0, maxVal]);
    }

    updateProjection() {
        this.projection = this.projection.translate([
            this.props.width / 2,
            this.props.width / widthHeightRatio / 2,
        ]);
    }

    makeCountries(svg: Selection<any, any, any, any>, data: WorldMapData) {
        svg.selectAll("path")
            .data(data.worldData)
            .join("path")
            .attr("d", geoPath(this.projection))
            .style("fill", "#999")
            .attr("stroke", "#222")
            .attr("stroke-width", "0.5 px");
    }

    fillCountries(
        svg: Selection<any, any, any, any>,
        data: WorldMapData,
        isSecond = false,
    ) {
        this.updateFill(
            data.maxInv,
            isSecond ? interpolateOranges : interpolateBlues,
        );
        svg.selectAll("path")
            .data(data.worldData)
            .filter(d => d.total !== undefined)
            .join("path")
            .style("fill", d => this.fill(d.total!.value.total));
    }

    fillOverlappingCountries(
        svg: Selection<any, any, any, any>,
        data1: WorldMapData,
        data2: WorldMapData,
    ) {
        this.updateFill(max([data1.maxInv, data2.maxInv]), interpolateGreens);
        const data = data1.worldData.map((d, i) => {
            return { data1: d, data2: data2.worldData[i] };
        });
        this.setState({
            maxInv3: max(
                data,
                (d: { data1: FeatureExt[] | any; data2: FeatureExt[] | any }) =>
                    d.data1.total !== undefined && d.data2.total !== undefined
                        ? d.data1.total.value.total + d.data2.total.value.total
                        : 0,
            ),
            show3: true,
        });
        svg.selectAll("path")
            .data(data)
            .filter(
                d => d.data1.total !== undefined && d.data2.total !== undefined,
            )
            .join("path")
            .style("fill", d =>
                this.fill(
                    d.data1.total!.value.total + d.data2.total!.value.total,
                ),
            );
    }

    addCursor(svg: Selection<any, any, any, any>, data: FeatureExt[]) {
        const label = this.props.label;
        const label2 = this.props.label2;
        const refCursor = this.refCursor.current;
        svg.selectAll("path")
            .data(data)
            .filter(d => d.total !== undefined)
            .attr("class", "country")
            .on("mousemove", function (d) {
                const coordinates = mouse(this as SVGPathElement);
                const x = coordinates[0];
                const y = coordinates[1];
                const target = select(refCursor)
                    .attr("r", 5) // explicit radius for firefox
                    .attr("fill", "none")
                    .attr("cx", x)
                    .attr("cy", y + 30) // 5 pixels above the cursor
                    .node();
                if (d.total !== undefined) {
                    tip.show({ feature: d, label: label, label2: label2 }, target);
                }
            })
            .on("mouseout", tip.hide);
    }

    createSVG() {
        this.updateProjection();

        const { impactCategory } = this.props;
        const svg = select(this.ref.current);
        svg.call(tip);

        if (this.props.multipleData) {
            const countries1 = unique(this.props.data!, config.COUNTRY);
            const countries2 = unique(this.props.data2!, config.COUNTRY);

            let data1: WorldMapData;
            let data2: WorldMapData;

            if (countries2.length === 0) {
                // empty selection 2
                data1 = prepareData(this.props.data!, impactCategory);
                this.setState({
                    maxInv1: data1.maxInv,
                    show1: true,
                    show2: false,
                });
                this.makeCountries(svg, data1);
                this.fillCountries(svg, data1);
                this.addCursor(svg, data1.worldData);
            } else if (countries1.length === 0) {
                // empty selection 1
                data2 = prepareData(this.props.data2!, impactCategory);
                this.setState({
                    maxInv2: data2.maxInv,
                    show1: false,
                    show2: true,
                });
                this.makeCountries(svg, data2);
                this.fillCountries(svg, data2, true);
                this.addCursor(svg, data2.worldData);
            } else {
                // checks whether country selections overlap to determine how to color map
                const isFullyOverlapping =
                    noIntersection(countries2, countries1).length === 0;
                const isPartialOverlapping =
                    intersection(countries2, countries1).length > 0;
                const noUniqueFirstSelection =
                    difference(countries1, countries2).length === 0;
                const noUniqueSecondSelection =
                    difference(countries2, countries1).length === 0;

                data1 = prepareData(this.props.data!, impactCategory);
                data2 = prepareData(this.props.data2!, impactCategory);
                this.makeCountries(svg, data1);
                if (isFullyOverlapping) {
                    // full overlap
                    this.setState({
                        maxInv1: data1.maxInv,
                        maxInv2: data2.maxInv,
                        show1: false,
                        show2: false,
                        show3: true,
                    });
                    this.fillOverlappingCountries(svg, data1, data2);
                    const merged = mergeSelectionsMap(
                        data1.worldData,
                        data2.worldData,
                    );
                    this.addCursor(svg, merged);
                } else {
                    if (noUniqueSecondSelection) {
                        // selection 2 fully overlaps selection 1
                        this.setState({
                            maxInv1: data1.maxInv,
                            maxInv2: data2.maxInv,
                            show1: true,
                            show2: false,
                        });
                        this.fillCountries(svg, data1);
                        if (isPartialOverlapping) {
                            this.fillOverlappingCountries(svg, data1, data2);
                            const merged = mergeSelectionsMap(
                                data1.worldData,
                                data2.worldData,
                            );
                            this.addCursor(svg, merged);
                        } else this.setState({ show3: false });
                    } else if (noUniqueFirstSelection) {
                        // selection 1 fully overlaps selection 2
                        this.setState({
                            maxInv1: data1.maxInv,
                            maxInv2: data2.maxInv,
                            show1: false,
                            show2: true,
                        });
                        this.fillCountries(svg, data2, true);
                        if (isPartialOverlapping) {
                            this.fillOverlappingCountries(svg, data1, data2);
                            const merged = mergeSelectionsMap(
                                data1.worldData,
                                data2.worldData,
                            );
                            this.addCursor(svg, merged);
                        } else this.setState({ show3: false });
                    } else {
                        // no overlaps, both selections exist
                        this.setState({
                            maxInv1: data1.maxInv,
                            maxInv2: data2.maxInv,
                            show1: true,
                            show2: true,
                            show3: false,
                        });
                        this.fillCountries(svg, data1);
                        this.fillCountries(svg, data2, true);
                        if (isPartialOverlapping)
                            this.fillOverlappingCountries(svg, data1, data2);
                        const merged = mergeSelectionsMap(
                            data1.worldData,
                            data2.worldData,
                        );
                        this.addCursor(svg, merged);
                    }
                }
            }
        } else {
            const data = prepareData(this.props.data!, impactCategory);
            this.setState({ maxInv1: data.maxInv });
            this.makeCountries(svg, data);
            this.fillCountries(svg, data);
            this.addCursor(svg, data.worldData);
        }
    }

    componentDidMount() {
        // initialize svg
        if (this.props.data !== null) {
            this.createSVG();
        }
    }

    componentDidUpdate(prevProps: WorldMapProps) {
        if (this.props !== prevProps) {
            this.createSVG();
        }
    }
    render() {
        const { show1, show2, show3 } = this.state;
        const maxValue1 = formatters[this.props.impactCategory](
            this.state.maxInv1,
        );
        const maxValue2 = formatters[this.props.impactCategory](
            this.state.maxInv2,
        );
        const maxValue3 = formatters[this.props.impactCategory](
            this.state.maxInv3,
        );

        // shifts bars appropriately if one or more are not shown
        const numLegendBars =
            boolToNumber(show1) + boolToNumber(show2) + boolToNumber(show3);
        const show1x = boolToNumber(show1);
        const show2x = boolToNumber(show1) + boolToNumber(show2);
        const show3x =
            boolToNumber(show1) + boolToNumber(show2) + boolToNumber(show3);
        // constants for legend bars
        const legendBarHeight = 15;
        const legendBarWidth = 400;
        const legendBarPadding = 5;
        const legendBarX = this.props.width / 2 - legendBarWidth / 2;
        const chartY = this.props.width / widthHeightRatio;

        // y values for legend bars and text
        const legend1y = chartY + show1x * (legendBarHeight + legendBarPadding);
        const legend2y = chartY + show2x * (legendBarHeight + legendBarPadding);
        const legend3y = chartY + show3x * (legendBarHeight + legendBarPadding);

        return (
            <div>
                <svg
                    viewBox={String([
                        0,
                        0,
                        this.props.width,
                        chartY +
                        (numLegendBars + 1) *
                        (legendBarHeight + legendBarPadding),
                    ])}
                    preserveAspectRatio={"xMinYMin meet"}
                    className="svg-content"
                >
                    <defs>
                        <LinearGradient1 />
                        <LinearGradient2 />
                        <LinearGradient3 />
                    </defs>
                    <circle ref={this.refCursor} id={"tipfollowscursor"} />
                    <g ref={this.ref} className={"countries"} />
                    {show1 && (
                        <LegendBar
                            y={legend1y}
                            value={maxValue1}
                            barWidth={legendBarWidth}
                            barHeight={legendBarHeight}
                            barX={legendBarX}
                            fill={"url(#Gradient1)"}
                        />
                    )}
                    {show2 && (
                        <LegendBar
                            y={legend2y}
                            value={maxValue2}
                            barWidth={legendBarWidth}
                            barHeight={legendBarHeight}
                            barX={legendBarX}
                            fill={"url(#Gradient2)"}
                        />
                    )}
                    {show3 && (
                        <LegendBar
                            y={legend3y}
                            value={maxValue3}
                            barWidth={legendBarWidth}
                            barHeight={legendBarHeight}
                            barX={legendBarX}
                            fill={"url(#Gradient3)"}
                        />
                    )}
                </svg>
            </div>
        );
    }
}

export default withRouter(WorldMap as any) as any;
