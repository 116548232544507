// update before deployment
const REACT_VERSION = "8e4ca33";
// types of investment
const JOBS = "Employment";
const GHG = "GHG";
const VA = "Value added";
const GENERAL = "General";


// output column names, should correspond to constants.py colOutNames
const columnNames = {
    CLIENT_NAME : "Client name/code",
    COMMENT : "Comment",
    CLIENT_TYPE : "Client type",
    COUNTRY : "Country/region of operations",
    INVESTEE : "Investee name",
    FISCAL_YEAR : "Fiscal year",
    REPORTING_YEAR : "Reporting year",
    POWER_PROD : "Power production",

    TYPE : "Scope",
    SUBTYPE : "Sub-scope",
    IS_LEAST_DEVELOPED : "Least Developed Countries (LDC)",
    IMPACT_CAT : "Impact indicator",
    SUBCAT : "Sub-indicator",
    ECON_ACT : "Economic activity",
    REVENUE : "Revenue",
    CAPITAL : "Outstanding amount",
    REGION : "Region Name",
    SUBREGION : "Sub-region Name",
    OUTSTANDING_ATTRIBUTION: "Attribution - Outstanding amount",
    OUTSTANDING_DEBT : "Attribution - Debt outstanding",
    OUTSTANDING_EQUITY : "Attribution - Equity outstanding",
    COMMITTED_ATTRIBUTION: "Attribution - Committed amount",
    COMMITTED_DEBT : "Attribution - Debt committed",
    COMMITTED_EQUITY : "Attribution - Equity committed",
    ATTRIBUTION_COM : "Attribution share - Committed",
    ATTRIBUTION_OUT : "Attribution share - Outstanding",
    INCOME_CLASS : "Income classification",
    YOUTH : "Youth employment share",
    CONFIDENCE : "Confidence level",
    TOTAL : "Total",
    ERRORS : "Errors",
    IMPACT_REVENUE : "Impact / mUSD revenue",
    IMPACT_CAPITAL : "Impact / mUSD outstanding amount",
};

const columnDictionary = {
    [columnNames.IMPACT_CAT] : "ImpactIndicator",
    [columnNames.SUBCAT] : "SubIndicator",
    [columnNames.TYPE] : "Scope",
    [columnNames.SUBTYPE] : "SubScope",
    [columnNames.IS_LEAST_DEVELOPED] : "LeastDeveloped",
    [columnNames.INCOME_CLASS] : "IncomeClassification",
    [columnNames.REGION] : "RegionName",
    [columnNames.SUBREGION] : "SubRegionName",
    [columnNames.COUNTRY] : "Country",
    [columnNames.ECON_ACT] : "EconomicActivity",
    [columnNames.FISCAL_YEAR] : "FiscalYear",
    [columnNames.REPORTING_YEAR] : "ReportingYear",
    [columnNames.CLIENT_NAME] : "ClientNameCode",
    [columnNames.CLIENT_TYPE] : "ClientType",
    [columnNames.INVESTEE] : "InvesteeName",
    [columnNames.REVENUE] : "Revenue",
    [columnNames.CAPITAL] : "FeCapital",
    [columnNames.POWER_PROD] : "PowerProduction",
    [columnNames.YOUTH] : "YouthEmploymentShare",
    [columnNames.CONFIDENCE] : "ConfidenceLevel",
    [columnNames.COMMITTED_ATTRIBUTION] : "CapitalCommittedAttribution",
    [columnNames.COMMITTED_DEBT] : "CapitalCommittedDebt",
    [columnNames.COMMITTED_EQUITY] : "CapitalCommittedEquity",
    [columnNames.OUTSTANDING_ATTRIBUTION] : "CapitalOutstandingAttribution",
    [columnNames.OUTSTANDING_DEBT] : "CapitalOutstandingDebt",
    [columnNames.OUTSTANDING_EQUITY] : "CapitalOutstandingEquity",
    [columnNames.ATTRIBUTION_COM] : "AttributionShareCommitted",
    [columnNames.ATTRIBUTION_OUT] : "AttributionShareOutstanding",
};

// types of impact
const subtypes = {
    DIRECT: "Direct",
    INDIRECT: "Supply Chain",
    INDUCED: "Induced",
    GENERAL: "General",
};

const typesImpact = {
    POWER: "Power enabling",
    FE: "Finance Enabling",
    BACK_PERM: "Backward Permanent",
    BACK_TEMP: "Backward Temporary",
    GENERAL: "General",
};
const subcategories = {
    ALL: "Total",
    F: "Female",
    VA: {
        WAGES: "Wages",
        TAXES: "Taxes",
        SAVE: "Savings",
    },
    GHG_SUBCAT: {
        AVOIDED: "Absolute emissions Avoided - CO2",
        EMITTED: "Absolute emissions Scope 1 - CO2",
        EMITTED_SCOPE_2: "Absolute emissions Scope 2 - CO2",
        EMITTED_SCOPE_3: "Absolute emissions Scope 3 Local - CO2",
        EMITTED_OTHER: "Absolute emissions Other - CO2",
        SEQUESTERED: "Emission removals",
        AVOIDED_NOC2: "Absolute emissions Avoided - Non-CO2",
        EMITTED_NOC2: "Absolute emissions Scope 1 - Non-CO2",
        EMITTED_SCOPE_2_NOC2: "Absolute emissions Scope 2 - Non-CO2",
        EMITTED_SCOPE_3_NOC2: "Absolute emissions Scope 3 Local - Non-CO2",
        EMITTED_OTHER_NOC2: "Absolute emissions Other - Non-CO2",
        //SEQUESTERED_NOC2: "Sequestered NCO2",
        EMITTED_SCOPE_3_IMPORTS: "Absolute emissions Scope 3 Imports - CO2",
        EMITTED_OTHER_IMPORTS: "Absolute emissions Other Imports - CO2",
        EMITTED_SCOPE_3_IMPORTS_NOC2: "Absolute emissions Scope 3 Imports - Non-CO2",
        EMITTED_OTHER_IMPORTS_NOC2: "Absolute emissions Other Imports - Non-CO2",
    },
    GENERAL: "General",
};

const emmissions = {
    CO2: [
        subcategories.GHG_SUBCAT.EMITTED,
        subcategories.GHG_SUBCAT.EMITTED_SCOPE_2,
        subcategories.GHG_SUBCAT.EMITTED_SCOPE_3,
        subcategories.GHG_SUBCAT.EMITTED_OTHER,
        subcategories.GHG_SUBCAT.EMITTED_SCOPE_3_IMPORTS,
        subcategories.GHG_SUBCAT.EMITTED_OTHER_IMPORTS,
    ],
    Non_CO2: [
        subcategories.GHG_SUBCAT.EMITTED_NOC2,
        subcategories.GHG_SUBCAT.EMITTED_SCOPE_2_NOC2,
        subcategories.GHG_SUBCAT.EMITTED_SCOPE_3_NOC2,
        subcategories.GHG_SUBCAT.EMITTED_OTHER_NOC2,
        subcategories.GHG_SUBCAT.EMITTED_SCOPE_3_IMPORTS_NOC2,
        subcategories.GHG_SUBCAT.EMITTED_OTHER_IMPORTS_NOC2,
    ]
}
// data column names
// when changing these constants, remember to change interface declarations for IData
const ATTRIBUTION_COM = "";
const ATTRIBUTION_OUT = "";
const COUNTRY = "Country";
const REPORTING_YEAR = "ReportingYear";
const FISCAL_YEAR = "FiscalYear";
const SECTOR = "EconomicActivity";
const LEAST_DEVELOPED = "LeastDeveloped";
const CLIENT_NAME = "ClientNameCode";
const INVESTEE = "InvesteeName";
const CLIENT_TYPE = "ClientType";
const REGION = "RegionName";
const SUBREGION = "SubRegionName";
const FINANCIAL_INSTRUMENT = "FinancialInstrument";
const INCOME_CLASS = "IncomeClassification";
const TYPE_IMPACT = "Scope";
const SUBTYPE_IMPACT = "Sub-scopeF";
const IMPACT_CATEGORY = "Impact indicator";
const IMPACT_SUBCATEGORY = "Sub-indicator";
const TOTAL = "Total";
const REVENUE = "Revenue";
const CAPITAL_OUTSTANDING = "FeCapital";
const POWER_PRODUCTION = "PowerProduction";
const CONF_LEVEL = "ConfidenceLevel";

export default {
    REACT_VERSION,
    JOBS,
    GHG,
    VA,
    GENERAL,
    subtypes,
    IMPACT_CATEGORY,
    IMPACT_SUBCATEGORY,
    INVESTEE,
    COUNTRY,
    INCOME_CLASS,
    REPORTING_YEAR,
    FISCAL_YEAR,
    SECTOR,
    LEAST_DEVELOPED,
    CLIENT_NAME,
    CLIENT_TYPE,
    CONF_LEVEL,
    REGION,
    SUBREGION,
    FINANCIAL_INSTRUMENT,
    SUBTYPE_IMPACT,
    TYPE_IMPACT,
    TOTAL,
    REVENUE,
    CAPITAL_OUTSTANDING,
    POWER_PRODUCTION,
    typesImpact,
    subcategories,
    ATTRIBUTION_COM,
    ATTRIBUTION_OUT,
    emmissions,
    columnDictionary,
};
