import React from "react";
import countries from "../utils/countries";

/**
 * Returns sorted unique values for property `name` in data.
 * @param data array of investment data
 * @param name name of property to get values of
 */
export const unique = (data: IData[], name: string) =>
    [...new Set(data.map(d => String(d[name])))].sort();

export const makeOptions = (uniqueValues: string[]) => {
    return uniqueValues.map(u => (
        <option value={u} key={u} title={u}>
            {u}
        </option>
    ));
};

const capitalizeRemoveUnderscores = (str: string) =>
    str
        .replace("_", " ")
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.slice(1)) // capitalizes first letter of each word
        .join(" ");

export const makeCountryOptions = (uniqueValues: string[]) => {
    // handles regions and subregions that are not mapped with ISO codes
    const countryNames = uniqueValues.map(u =>
        countries.getName(u, "en") === undefined
            ? [capitalizeRemoveUnderscores(String(u)), u]
            : [countries.getName(u, "en"), u],
    );
    return countryNames.map(u => (
        <option value={u[1]} key={u[1]} title={u[0]}>
            {u[0]}
        </option>
    ));
};
