import React, { useEffect, useState } from "react";
import config from "../config";
import { makeCountryOptions, makeOptions } from "../utils/makeOptions";

interface SelectProps {
    label: string;
    name: string;
    filter: string[];
    selectedFilter: string[];
    multiple: boolean;
    setFunction: (name: string, filter: string[]) => void;
}

const Select = (props: SelectProps) => {
    const { label, name, multiple, filter, selectedFilter } = props;
    const [options, setOptions] = useState<JSX.Element[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // if "All" is selected return all options
        const allOptions = [...e.currentTarget.options];
        const selected = allOptions.filter(f => f.selected).map(f => f.value);
        let options = [];
        if (selected.indexOf("All") === 0) {
            options = allOptions.map(f => f.value);
        } else {
            options = selected;
        }
        props.setFunction(name, options);
    };


    useEffect(() => {
        const optionElements =
            name === config.COUNTRY
                ? makeCountryOptions(filter)
                : makeOptions(filter);
        setOptions(optionElements);
    }, [filter, name]);

    const value = selectedFilter;
    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <select
                className="u-full-width multi-select"
                id={name}
                onChange={handleChange}
                // if multiple value needs to be a scalar
                value={multiple ? value : value[0]}
                multiple={multiple}
                size={3}
            >
                {options}
            </select>
        </div>
    );
};

Select.defaultProps = {
    multiple: true,
};

export default Select;
