import React from "react";
import Row from "../components/Row";
import { useTranslation } from "react-i18next";
import { useMountEffect } from "../utils/useMountEffect";

const PasswordRecoveryEmailSent = () => {
    const { t } = useTranslation();
    useMountEffect(() => {
        document.title = t("passwordRecovery.title");
    });
    return (
        <div className="container">
            <Row>
                <div className="col-12 col-md-5 App">
                    <h1>{t("passwordRecovery.title")}</h1>
                    <p>{t("passwordRecovery.description")}</p>
                </div>
            </Row>
        </div>
    );
};

export default PasswordRecoveryEmailSent;
