import { select } from "d3-selection";
import { saveAs } from "file-saver";
import React, { useRef, useState } from "react";
import Download from "../icons/Download";
import Info from "../icons/Info";
interface IChartProps {
    title: string;
    info?: string;
    download?: boolean;
    fileName?: string;
}

const Chart: React.FC<IChartProps> = props => {
    const ref = useRef(null);
    const [showInfo, setshowInfo] = useState(false);

    const handleDownload = (e: any) => {
        e.preventDefault();
        const svg = select(ref.current).select("svg");

        svg.attr("title", props.title)
            .attr("version", 1.1)
            .attr("xmlns", "http://www.w3.org/2000/svg");

        const node = svg.node() as HTMLElement;
        const html = node.outerHTML;
        const blob = new Blob([html], {
            type: "image/svg+xml",
        });
        saveAs(blob, props.fileName!);
    };

    return (
        <div className="block">
            <div className="title-bar">
                <h5>{props.title}</h5>
                <div>
                    {props.download && (
                        <Download size={20} onClick={handleDownload} />
                    )}
                    {props.info && (
                        <Info
                            size={20}
                            onMouseEnter={() => setshowInfo(true)}
                            onMouseLeave={() => setshowInfo(false)}
                        />
                    )}
                    {showInfo && (
                        <div className="d3-tip info">{props.info}</div>
                    )}
                </div>
            </div>
            <div ref={ref}>{props.children}</div>
        </div>
    );
};

export default Chart;
