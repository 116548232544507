import React from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import UploadCloud from "../icons/uploadCloud";
import LogoutIcon from "../icons/Logout";
import { useTranslation } from "react-i18next";
import jimLogo from "../logos/jim.png";
import paths from "../paths";

const Navbar = () => {
    const dispatch = useDispatch();
    const logout = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        axios
            .post("/api/token/remove")
            .then(res => {
                dispatch({ type: "LOGOUT" });
            })
            .catch(e => console.error(e));
    };
    const data = useSelector((state: ReduxState) => state.data.isThereData);
    const loggedIn = useSelector((state: ReduxState) => state.user.loggedIn);
    const unauthorized = useSelector((state: ReduxState) => state.user.unauthorized);

    const { t } = useTranslation();
    return (
        <nav className="navbar">
            <div className="container-fluid">
                <ul className="navbar-list">
                    <li className="navbar-item">
                        <Link className="navbar-link" to="/">
                            <img alt={t("app.title")} src={jimLogo}/>
                        </Link>
                    </li>
                    {unauthorized && 
                        <li className="navbar-link navbar-item">
                            <label className="error">
                                {t("auth.unauthorized")}
                            </label>
                        </li>
                    }

                    {loggedIn && !unauthorized ? (
                        <>
                            {data && (
                                <li className="navbar-item">
                                    <NavLink
                                        className="navbar-link navbar-item"
                                        activeClassName="active"
                                        exact
                                        to={paths.dashboard}
                                    >
                                        {t("portfolio.title")}
                                    </NavLink>
                                </li>
                            )}
                            {data && (
                                <NavLink
                                    className="navbar-link navbar-item"
                                    activeClassName="active"
                                    exact
                                    to={"/comparison"}
                                >
                                    {t("comparison.title")}
                                </NavLink>
                            )}
                            <li className="navbar-item icon upload">
                                <NavLink
                                    className="navbar-link navbar-item"
                                    activeClassName="active"
                                    title={t("navbar.upload")}
                                    to={"/upload"}
                                >
                                    <UploadCloud size={20} />
                                </NavLink>
                            </li>
                        </>
                    ) : null}

                    {loggedIn && !unauthorized && (
                        <li className="navbar-item right icon logout">
                            <a
                                className="navbar-link"
                                onClick={logout}
                                href="/auth"
                                title={t("navbar.logout")}
                            >
                                <LogoutIcon size={20} />
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
