import { max } from "d3-array";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "d3-scale";
import { select } from "d3-selection";
import d3tip from "d3-tip";
import "d3-transition/src/selection";
import { default as React, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import { formatters } from "../utils/formatters";
import { reduceTotal } from "../utils/reducers";
import { duration, innerPadding } from "./constants";

const reduceSubtype = (data: IData[], key: string) =>
    data.filter(d => d.SubScope === key).reduce(reduceTotal(), 0);

export const stackFun = (dataArr: IData[][], labels: string[]) => {
    const dataFinal: { [key: string]: number }[] = [];
    dataArr.forEach((data, i) => {
        const dataBackward = data.filter(
            d =>
                d[config.TYPE_IMPACT] === config.typesImpact.BACK_PERM ||
                d[config.TYPE_IMPACT] === config.typesImpact.BACK_TEMP,
        );
        dataFinal.push({
            id: dataArr.length === 1 ? "" : labels[i],
            reportingyear: data.length !== 0 ? data[0][config.REPORTING_YEAR] : 0,
            fiscalyear: data.length !== 0 ? data[0][config.FISCAL_YEAR] : 0,
            [config.subtypes.DIRECT]: reduceSubtype(
                dataBackward,
                config.subtypes.DIRECT,
            ),
            [config.subtypes.INDIRECT]: reduceSubtype(
                dataBackward,
                config.subtypes.INDIRECT,
            ),
            [config.subtypes.INDUCED]: reduceSubtype(
                dataBackward,
                config.subtypes.INDUCED,
            ),
            [config.typesImpact.POWER]: data
                .filter(d => d[config.TYPE_IMPACT] === config.typesImpact.POWER)
                .reduce(reduceTotal(), 0),
            [config.typesImpact.FE]: data
                .filter(d => d[config.TYPE_IMPACT] === config.typesImpact.FE)
                .reduce(reduceTotal(), 0),
        });
    });
    return dataFinal;
};

export interface GroupedChartProps {
    width: number;
    height: number;
    data: IData[][];
    alternateText?: boolean;
    keys: string[];
    impactCategory: string;
    labels: string[];
}

export interface StackedData {
    reportingyear: number;
    fiscalyear: number;
    Direct: number;
    Indirect: number;
    Induced: number;
    [propName: string]: number;
}

const margin = { top: 10, right: 10, bottom: 20, left: 55 };
const groupKey = "id";

const color = scaleOrdinal().range([
    "#08306b",
    "#4292c6",
    "#c6dbef",
    "#C74342",
    "#FFEC99",
]);

const Chart = (props: GroupedChartProps) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const tip = d3tip()
        .attr("class", "d3-tip")
        .html((d: { key: string; value: number }) => {
            return `${d.key}<br><b>${formatters[props.impactCategory](
                d.value,
            )}</b>`;
        });

    const data = useMemo(() => stackFun(props.data, props.labels), [props.data, props.labels]);
    const maxY = useMemo(
        () =>
            max(data, (d: StackedData) =>
                max(props.keys, (key: string) => d[key]),
            ),
        [data, props.keys],
    );
    const yScale = useMemo(
        () =>
            scaleLinear()
                .domain([0, maxY === undefined ? 0 : maxY])
                .nice()
                .rangeRound([props.height - margin.bottom, margin.top]),
        [maxY, props.height],
    );
    const yAxis = useMemo(
        () =>
            axisLeft(yScale).tickFormat(
                formatters[props.impactCategory] as any,
            ),
        [yScale, props.impactCategory],
    );

    const x0Scale = useMemo(
        () =>
            scaleBand()
                .domain(data.map((d: any) => String(d[groupKey])))
                .rangeRound([margin.left, props.width - margin.right])
                .paddingInner(innerPadding),
        [data, props.width],
    );

    const x1Scale = useMemo(
        () =>
            scaleBand()
                .domain(props.keys)
                .rangeRound([0, x0Scale.bandwidth()])
                .padding(0.05),
        [props.keys, x0Scale],
    );
    const x0Axis = useMemo(() => axisBottom(x0Scale), [x0Scale]);

    useEffect(() => {
        const svg = select(ref.current).select("svg").call(tip);
        const t = svg.transition().duration(duration);
        // y axis
        svg.select(".y.axis")
            .attr("transform", `translate(${margin.left}, 0)`)
            .transition(t)
            .call(yAxis as any);

        // x axis
        svg.select(".x.axis")
            .attr("transform", `translate(0, ${props.height - margin.bottom})`)
            .transition(t)
            .call(x0Axis as any);

        const groups = svg
            .selectAll(".stack")
            .data(data, (d: any) => d[groupKey] as any)
            .join("g")
            .attr("class", "stack")
            .attr(
                "transform",
                (d: any) => `translate(${x0Scale(String(d[groupKey]))},0)`,
            );

        groups
            .selectAll("rect")
            .data((d: any) => props.keys.map(key => ({ key, value: d[key] })))
            .join(
                enter =>
                    enter.append("rect").call(enter =>
                        enter
                            .transition(t)
                            .attr("y", d => yScale(d.value))
                            .attr("height", d => yScale(0) - yScale(d.value)),
                    ),
                update =>
                    update.call(update =>
                        update
                            .transition(t)
                            .attr("y", d => yScale(d.value))
                            .attr("height", d => yScale(0) - yScale(d.value)),
                    ),
                exit => exit.remove(),
            )
            .attr("class", "bar")
            .attr("x", d => String(x1Scale(d.key)))
            .attr("width", x1Scale.bandwidth())
            .attr("fill", d => String(color(d.key)))
            .on("mouseover", tip.show)
            .on("mouseout", tip.hide);
    }, [
        data,
        yScale,
        yAxis,
        x0Scale,
        x1Scale,
        x0Axis,
        props.keys,
        props.height,
        tip,
    ]);
    // remove x axis tick if there is only one selection
    useEffect(() => {
        if (props.data.length === 1) {
            const ticks = select("g.x.axis").selectAll("g.tick");
            ticks.remove();
        }
    }, [props.data]);

    const legendY = props.height + 15;
    const legendTextY = legendY - 2;

    const textPadding = squareSize + 5;
    return (
        <div ref={ref}>
            <svg
                viewBox={String([0, 0, props.width, props.height + 15])}
                preserveAspectRatio={"xMinYMin meet"}
                className="svg-content"
            >
                <g className="y axis" />
                <g className="x axis" />
                <g>
                    <LegendSquare x={0} legendY={legendY} colorOrder="0" />
                    <LegendText
                        y={legendTextY}
                        x={textPadding}
                        value={t("tables.results.direct")}
                    />

                    <LegendSquare x={70} legendY={legendY} colorOrder="1" />
                    <LegendText
                        y={legendTextY}
                        x={70 + textPadding}
                        value={t("tables.results.indirect")}
                    />

                    <LegendSquare x={180} legendY={legendY} colorOrder="2" />
                    <LegendText
                        y={legendTextY}
                        x={180 + textPadding}
                        value={t("tables.results.induced")}
                    />

                    <LegendSquare x={260} legendY={legendY} colorOrder="3" />
                    <LegendText
                        y={legendTextY}
                        x={260 + textPadding}
                        value={t("tables.results.power")}
                    />

                    <LegendSquare x={380} legendY={legendY} colorOrder="4" />
                    <LegendText
                        y={legendTextY}
                        x={380 + textPadding}
                        value={t("tables.results.fe")}
                    />
                </g>
            </svg>
        </div>
    );
};

export default Chart;

const squareSize = 12;

interface ILegendSquareProps {
    legendY: number;
    x: number;
    colorOrder: string;
}
const LegendSquare = (props: ILegendSquareProps) => (
    <rect
        y={props.legendY - squareSize}
        width={squareSize}
        height={squareSize}
        fill={String(color(props.colorOrder))}
        x={props.x}
    />
);

interface ILegendTextProps {
    value: string;
    y: number;
    x: number;
}

const LegendText = (props: ILegendTextProps) => (
    <text y={props.y} x={props.x} style={{ font: "12px sans-serif" }}>
        {props.value}
    </text>
);
