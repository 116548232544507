import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import classnames from "classnames";
import { BoxErrorTable } from "./Box";
import { Trans, useTranslation } from "react-i18next";
import fileDownload from "js-file-download";
import LoadingSpinner from "../views/LoadingSpinner";
import XLSX from "xlsx";
import paths from "../paths";
import config from "../config";

const renameKeys = function (obj:any , newKeys:any) {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

export const xlsxParse = function (
    ab: ArrayBuffer
): unknown[] {
    var wb = XLSX.read(ab, { type: "array" });
    let payload: any[] = []
    for (var i = 0; i < 4; ++i) {
        var sheet_name = wb.SheetNames[i];
        var worksheet = wb.Sheets[sheet_name];
        var ws_json = XLSX.utils.sheet_to_json(worksheet);
        var ws_renamed_json = ws_json.map(obj => renameKeys(obj, config.columnDictionary))
        payload = payload.concat(ws_renamed_json);
    }

    return payload;
}

const UploadFile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [file, setFile] = useState<null | File>(null);
    const [licenseAccepted, setLicenseAccepted] = useState<boolean>(false);
    const [uploadProgress] = useState("");
    const [loading, setLoading] = useState(false);
    const handleChange = () => {
        const files = (document.getElementById(
            "fileSelector",
        ) as HTMLInputElement).files;

        if (files !== null) {
            setFile(files[0]);
        }
    };

    const handleAcceptLicenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicenseAccepted(event.target.checked);
    };

    const history = useHistory();
    const [cookies] = useCookies();
    const upload = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        dispatch({ type: "UPLOAD" });
        // prepare form to upload file
        let formData = new FormData();
        if (file !== null) {
            formData.append("file", file as any);
            const { csrf_refresh_token } = cookies;
            setLoading(true);
            fetch("/api/upload", {
                credentials: "same-origin",
                headers: {
                    "X-CSRF-TOKEN": csrf_refresh_token,
                },
                body: formData,
                method: "POST",
            })
                .then(res => {
                    setLoading(false);
                    if (res.status === 401) {
                        dispatch({ type: "UNAUTHORIZED" });
                    }
                    else if (res.status >= 400) {
                        res.json().then(json =>
                            dispatch({
                                type: "FAILED_UPLOAD",
                                payload: {
                                    message: json,
                                },
                            }),
                        );
                    } else {
                        const contentType = res.headers.get("content-type");
                        if (contentType === "application/vnd.ms-excel") {
                            res.arrayBuffer().then(ab => {
                                fileDownload(new Blob([ab], { type: "application/vnd.ms-excel" }),
                                    `${file.name.split(".")[0]
                                    }-JIM-results.xlsx`);
                                dispatch({
                                    type: "DATA_RECEIVED",
                                    payload: xlsxParse(ab),
                                });
                                history.push(paths.dashboard);
                            });

                        }
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        dispatch({ type: "UNAUTHORIZED" });
                    }
                    dispatch({ type: "FAILED_UPLOAD", payload: err });
                    console.error(err);
                });
        }
    };

    const error = useSelector((state: ReduxState) => state.data.errorUploading);
    if (error) {
        console.log(error);
    }
    const classesProgressBar = classnames(
        "progress-bar progress-bar-striped progress-bar-animated",
        error === null ? "success" : "error",
    );
    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            {error !== null && <BoxErrorTable data={error.message}></BoxErrorTable>}
            {uploadProgress && (
                <div
                    className={classesProgressBar}
                    style={{ width: `${uploadProgress}%` }}
                >
                    {uploadProgress}
                </div>
            )}

            <input
                id="fileSelector"
                type="file"
                accept={".xls,.xlsx"}
                onChange={handleChange}
                className={"u-full-width"}
            />

            <label htmlFor="acceptTerms">
                <input type="checkbox" id="acceptTerms" name="acceptTerms" onChange={handleAcceptLicenseChange} />
                <span className="label-body"><Trans i18nKey="uploadFile.agreeToLicense">I agree to the Terms and Conditions as stated in the <a href={paths.licenseAgreement} target="_blank" rel='noopener noreferrer'>License Agreement</a></Trans></span>
            </label>

            <input
                className={"button-primary u-full-width"}
                type="button"
                value={String(t("upload.button"))}
                onClick={e => upload(e)}
                disabled={file === null || !licenseAccepted}
            />
        </div>
    );
};

export default UploadFile;