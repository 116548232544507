import { format } from "d3-format";
import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import { Attribution } from "../utils/filters";
import { reduceTotal, reduceWithAttribution } from "../utils/reducers";

export const filterData = (data: IData[]) =>
    data.filter(
        // avoids showing duplicate information
        d =>
            d.ImpactIndicator === config.GENERAL &&
            d.SubScope === config.subtypes.GENERAL,
    );

export const prepareData = (data: IData[], attribution: string) => {
    let reportingyear = data.length !== 0 ? data[0][config.REPORTING_YEAR] : null;
    let fiscalyear = data.length !== 0 ? data[0][config.FISCAL_YEAR] : null;
    const numberInvestments = [...new Set(data.map(d => d[config.CLIENT_NAME]))]
        .length;

    for(let d in data)
    {
        if(data[d][config.REPORTING_YEAR] !== reportingyear)
        {
            reportingyear = 'All';
            break;
        }
    }
    for(let d in data)
    {
        if(data[d][config.FISCAL_YEAR] !== fiscalyear)
        {
            fiscalyear = 'All';
            break;
        }
    }

    let reducer = reduceTotal(config.REVENUE);
    switch (attribution) {
        case Attribution.Com:
            reducer = reduceWithAttribution(Attribution.Com);
            break;
        case Attribution.Out:
            reducer = reduceWithAttribution(Attribution.Out);
            break;
        default:
            break;
    }

    const dataRevenue = data.filter(
        d =>
            d.Scope !== config.typesImpact.FE,
    );

    const totalRevenue = format("($,.2f")(
        dataRevenue.reduce(
            reducer,
            0,
        ) / 1_000_000,
    );

    const dataInvestments = data.filter(
        d =>
            d.Scope === config.typesImpact.FE,
    );

    const totalOutstanding = format("($,.2f")(
        dataInvestments.reduce(
            reduceTotal(config.CAPITAL_OUTSTANDING),
            0,
        ) / 1_000_000,
    );

    const totalPowerProduction = format(",.0f")(
        data.reduce(
            reduceTotal(config.POWER_PRODUCTION),
            0,
        ),
    );

    const numberCountries = [...new Set(data.map(d => d[config.COUNTRY]))]
        .length;

    const numberSectors = [...new Set(data.map(d => d[config.SECTOR]))].length;

    return {
        reportingyear,
        fiscalyear,
        numberInvestments,
        totalRevenue,
        totalOutstanding,
        totalPowerProduction,
        numberCountries,
        numberSectors,
    };
};

interface InputTableProps {
    showCountry: boolean;
    data: IData[];
    data2?: IData[];
    attribution: string;
    attribution2?: string;
    label?: string;
    label2?: string;
}

const InputTable = (props: InputTableProps) => {
    const { t } = useTranslation();
    const data = prepareData(filterData(props.data), props.attribution);

    let data2;
    if (props.data2 && props.attribution2) {
        data2 = prepareData(filterData(props.data2), props.attribution2);
    }

    return (
        <table className="u-full-width aligned-r">
            {props.label && props.label2 && (
                <thead>
                    <tr>
                        <th />
                        <th>{props.label}</th>
                        <th>{props.label2}</th>
                    </tr>
                </thead>
            )}
            <tbody>
                <tr>
                    <th>{t("tables.inputs.reportingyear")}</th>
                    <td>{data.reportingyear}</td>
                    {data2 && <td>{data2.reportingyear}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.fiscalyear")}</th>
                    <td>{data.fiscalyear}</td>
                    {data2 && <td>{data2.fiscalyear}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.investments")}</th>
                    <td>{data.numberInvestments}</td>
                    {data2 && <td>{data2.numberInvestments}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.revenue")}</th>
                    <td>{data.totalRevenue}</td>
                    {data2 && <td>{data2.totalRevenue}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.capital")}</th>
                    <td>{data.totalOutstanding}</td>
                    {data2 && <td>{data2.totalOutstanding}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.power")}</th>
                    <td>{data.totalPowerProduction}</td>
                    {data2 && <td>{data2.totalPowerProduction}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.countries")}</th>
                    <td>{data.numberCountries}</td>
                    {data2 && <td>{data2.numberCountries}</td>}
                </tr>
                <tr>
                    <th>{t("tables.inputs.sectors")}</th>
                    <td>{data.numberSectors}</td>
                    {data2 && <td>{data2.numberSectors}</td>}
                </tr>
            </tbody>
        </table>
    );
};

InputTable.defaultProps = {
    showCountry: true,
};

export default InputTable;
