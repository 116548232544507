import React, { useEffect } from "react";
import paths from "../paths";

export default function Homepage() {

  useEffect(() => {
    window.location.href = paths.wix;  }, []);

  return (
    <div>
      <h2>Homepage</h2>
    </div>
  );
}