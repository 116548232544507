import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import GHGIcon from "../icons/GHG";
import VAIcon from "../icons/trendingUp";
import JobsIcon from "../icons/users";
import { fJobs } from "../utils/formatters";
import { reduceTotal } from "../utils/reducers";

const getValueConfLevel = (data: IData[]) =>
{
    const valueTotal = data.reduce(reduceTotal(), 0);
    const conf = Math.floor(
        data.reduce(reduceTotal(config.CONF_LEVEL), 0) / data.length,
    );
    return {
        value: valueTotal,
        conf: valueTotal > 0 ? "*".repeat(conf) : "",
    };
};

interface Result
{
    value: number;
    conf: string;
}

const emptyVal: Result = { value: 0, conf: "" };

interface Results
{
    directImpact: Result;
    indirectImpact: Result;
    inducedImpact: Result | null;
    powerImpact: Result;
    feTotalImpact: Result;
    feDirectImpact: Result;
    feIndirectImpact: Result;
    feInducedImpact: Result;
}

export const prepareData = (data: IData[], impactCategory: string, subcat: Object | null): Results =>
{
    if (data.length === 0) {
        return {
            directImpact: emptyVal,
            indirectImpact: emptyVal,
            inducedImpact: emptyVal,
            powerImpact: emptyVal,
            feTotalImpact: emptyVal,
            feDirectImpact: emptyVal,
            feIndirectImpact: emptyVal,
            feInducedImpact: emptyVal,
        };
    }
    const dataImpactCategory = data.filter(
        d => d.ImpactIndicator === impactCategory && d.SubIndicator !== "Female" && d.SubIndicator !== "Formal" && d.SubIndicator !== "Informal",
    );
    let dataSubCategory = null;
    if (subcat !== null) {
        dataSubCategory = data.filter(
            d => Object.values(subcat).includes(d.SubIndicator)
        );
    }
    else {
        dataSubCategory = dataImpactCategory;
    }
    const dataBackLink = dataSubCategory.filter(
        d =>
            d.Scope === config.typesImpact.BACK_PERM ||
            d.Scope === config.typesImpact.BACK_TEMP,
    );

    let direct = dataBackLink.filter(
        d => d.SubScope === config.subtypes.DIRECT,
    );
    if (impactCategory === "GHG") {
        direct = direct.filter(
            d => d.SubIndicator !== config.subcategories.GHG_SUBCAT.SEQUESTERED
        );
    }
    const directImpact = getValueConfLevel(direct);
    const indirect = dataBackLink.filter(
        d => d.SubScope === config.subtypes.INDIRECT,
    );
    const indirectImpact = getValueConfLevel(indirect);

    const induced = dataBackLink.filter(
        d => d.SubScope === config.subtypes.INDUCED,
    );
    const inducedImpact =
        induced.length === 0 ? null : getValueConfLevel(induced);
    const power = dataSubCategory.filter(
        d => d.Scope === config.typesImpact.POWER,
    );
    const powerImpact = getValueConfLevel(power);

    const fe = dataSubCategory.filter(d => d.Scope === config.typesImpact.FE);
    const feDirect = dataSubCategory.filter(d => d.Scope === config.typesImpact.FE && d.SubScope === config.subtypes.DIRECT);
    const feIndirect = dataSubCategory.filter(d => d.Scope === config.typesImpact.FE && d.SubScope === config.subtypes.INDIRECT);
    const feInduced = dataSubCategory.filter(d => d.Scope === config.typesImpact.FE && d.SubScope === config.subtypes.INDUCED);

    const feTotalImpact = getValueConfLevel(fe);
    const feDirectImpact = getValueConfLevel(feDirect);
    const feIndirectImpact = getValueConfLevel(feIndirect);
    const feInducedImpact = getValueConfLevel(feInduced);

    return {
        directImpact,
        indirectImpact,
        inducedImpact,
        powerImpact,
        feTotalImpact,
        feDirectImpact,
        feIndirectImpact,
        feInducedImpact,
    };
};

interface ResultsTableContainerProps
{
    feExpanded?: boolean,
    setFeExpanded?: (input: boolean) => any
}

export const ResultsTableContainer: React.FC<ResultsTableContainerProps> = ({ children, feExpanded = false, setFeExpanded }) =>
{
    const { t } = useTranslation();
    return (
        <table className="u-full-width aligned-r conf-level">
            <thead>
                <tr>
                    <th />
                    <th colSpan={2}>{t("tables.results.direct")}</th>
                    <th colSpan={2}>{t("tables.results.indirect")}</th>
                    <th colSpan={2}>{t("tables.results.induced")}</th>
                    <th colSpan={2}>{t("tables.results.power")}</th>
                    {!feExpanded &&
                        <th colSpan={2}>
                            <div>{t("tables.results.fe")}</div>
                            {setFeExpanded !== undefined &&
                                <Expander expanded={feExpanded} setExpanded={setFeExpanded} />
                            }
                        </th>}
                    {feExpanded && setFeExpanded &&
                        <th colSpan={6}>
                            <div>{t("tables.results.fe")}</div>
                            <Expander expanded={feExpanded} setExpanded={setFeExpanded} />
                        </th>}
                </tr>
                { // add second 'header' row for subcategories for finance enabling data if enabled
                    feExpanded !== undefined && feExpanded &&
                    <tr>
                        <th />
                        <th colSpan={2} />
                        <th colSpan={2} />
                        <th colSpan={2} />
                        <th colSpan={2} />
                        <th colSpan={2}>{t("tables.results.direct")}</th>
                        <th colSpan={2}>{t("tables.results.indirect")}</th>
                        <th colSpan={2}>{t("tables.results.induced")}</th>
                    </tr>
                }
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

interface ResultsTableRowProps
{
    data: Results,
    feExpanded?: boolean,
}

export const ResultsTableRow = ({ data, feExpanded = false }: ResultsTableRowProps) =>
{
    return <>
        <td>{fJobs(data.directImpact.value)}</td>
        <td>{data.directImpact.conf}</td>
        <td>{fJobs(data.indirectImpact.value)}</td>
        <td>{data.indirectImpact.conf}</td>
        <td>
            {data.inducedImpact !== null && fJobs(data.inducedImpact.value)}
        </td>
        <td>{data.inducedImpact?.conf}</td>
        <td>{fJobs(data.powerImpact.value)}</td>
        <td>{data.powerImpact.conf}</td>
        { // show only one column with total for finance enabling
            !feExpanded && <>
                <td>{fJobs(data.feTotalImpact.value)}</td>
                <td>{data.feTotalImpact.conf}</td>
            </>}
        { // show 3 subcategories for finance enabling if expanded
            feExpanded && <>
                <td>{fJobs(data.feDirectImpact.value)}</td>
                <td>{data.feDirectImpact.conf}</td>
                <td>{fJobs(data.feIndirectImpact.value)}</td>
                <td>{data.feIndirectImpact.conf}</td>
                <td>{fJobs(data.feInducedImpact.value)}</td>
                <td>{data.feInducedImpact.conf}</td>
            </>}
    </>
};

interface ExpanderProps
{
    expanded: boolean,
    setExpanded: (input: boolean) => any
}

export const Expander = ({ expanded, setExpanded }: ExpanderProps) =>
{
    const { t } = useTranslation();
    return (
        <div className="expandable" onClick={() => setExpanded(!expanded)}>
            {expanded ? t("tables.results.hideDetails") : t("tables.results.showDetails")}
        </div>
    );
};

interface ResultsTableProps
{
    data: IData[];
}

export default (props: ResultsTableProps) =>
{
    const { t } = useTranslation();
    const dataJobsTotal = prepareData(props.data, config.JOBS, null);
    const dataJobsFemale = prepareData(props.data, config.JOBS, { cat: config.subcategories.F });
    const dataJobsMale = {
        directImpact: {
            conf: dataJobsTotal.directImpact.conf,
            value: dataJobsTotal.directImpact.value - dataJobsFemale.directImpact.value,
        },
        indirectImpact: {
            conf: dataJobsTotal.indirectImpact.conf,
            value: dataJobsTotal.indirectImpact.value - dataJobsFemale.indirectImpact.value,
        },
        inducedImpact: dataJobsTotal.inducedImpact ? {
            conf: dataJobsTotal.inducedImpact.conf,
            value: dataJobsFemale.inducedImpact ?
                dataJobsTotal.inducedImpact.value - dataJobsFemale.inducedImpact.value :
                dataJobsTotal.inducedImpact.value,
        } : null,
        powerImpact: {
            conf: dataJobsTotal.powerImpact.conf,
            value: dataJobsTotal.powerImpact.value - dataJobsFemale.powerImpact.value,
        },
        feTotalImpact: {
            conf: dataJobsTotal.feTotalImpact.conf,
            value: dataJobsTotal.feTotalImpact.value - dataJobsFemale.feTotalImpact.value,
        },
        feDirectImpact: {
            conf: dataJobsTotal.feDirectImpact.conf,
            value: dataJobsTotal.feDirectImpact.value - dataJobsFemale.feDirectImpact.value,
        },
        feIndirectImpact: {
            conf: dataJobsTotal.feIndirectImpact.conf,
            value: dataJobsTotal.feIndirectImpact.value - dataJobsFemale.feIndirectImpact.value,
        },
        feInducedImpact: {
            conf: dataJobsTotal.feInducedImpact.conf,
            value: dataJobsTotal.feInducedImpact.value - dataJobsFemale.feInducedImpact.value,
        },
    }
    const dataGHGtotal = prepareData(props.data, config.GHG, config.subcategories.GHG_SUBCAT);
    const dataGHGco2 = prepareData(props.data, config.GHG, config.emmissions.CO2);
    const dataGHGnonco2 = prepareData(props.data, config.GHG, config.emmissions.Non_CO2);
    const dataVAtotal = prepareData(props.data, config.VA, null);
    const dataVAwages = prepareData(props.data, config.VA, { cat: config.subcategories.VA.WAGES });
    const dataVAtaxes = prepareData(props.data, config.VA, { cat: config.subcategories.VA.TAXES });
    const dataVAsavings = prepareData(props.data, config.VA, { cat: config.subcategories.VA.SAVE });

    const [jobsExpanded, setJobsExpanded] = useState(false);
    const [ghgExpanded, setGhgExpanded] = useState(false);
    const [vaExpanded, setVaExpanded] = useState(false);
    const [feExpanded, setFeExpanded] = useState(false);

    return <>
        <ResultsTableContainer feExpanded={feExpanded} setFeExpanded={setFeExpanded}>
            <tr>
                <th>
                    <JobsIcon size={15} />
                    {t("tables.results.jobs.total")}
                    <Expander expanded={jobsExpanded} setExpanded={setJobsExpanded} />
                </th>
                <ResultsTableRow data={dataJobsTotal} feExpanded={feExpanded} />
            </tr>
            {jobsExpanded && <>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.jobs.female")}
                    </th>
                    <ResultsTableRow data={dataJobsFemale} feExpanded={feExpanded} />
                </tr>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.jobs.male")}
                    </th>
                    <ResultsTableRow data={dataJobsMale} feExpanded={feExpanded} />
                </tr>
            </>}
            <tr>
                <th>
                    <GHGIcon size={15} />
                    {t("tables.results.ghg.total")}
                    <Expander expanded={ghgExpanded} setExpanded={setGhgExpanded} />
                </th>
                <ResultsTableRow data={dataGHGtotal} feExpanded={feExpanded} />
            </tr>
            {ghgExpanded && <>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.ghg.co2")}
                    </th>
                    <ResultsTableRow data={dataGHGco2} feExpanded={feExpanded} />
                </tr>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.ghg.non_co2")}
                    </th>
                    <ResultsTableRow data={dataGHGnonco2} feExpanded={feExpanded} />
                </tr>
            </>}
            <tr>
                <th>
                    <VAIcon size={15} />
                    {t("tables.results.va.total")}
                    <Expander expanded={vaExpanded} setExpanded={setVaExpanded} />
                </th>
                <ResultsTableRow data={dataVAtotal} feExpanded={feExpanded} />
            </tr>
            {vaExpanded && <>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.va.wages")}
                    </th>
                    <ResultsTableRow data={dataVAwages} feExpanded={feExpanded} />
                </tr>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.va.taxes")}
                    </th>
                    <ResultsTableRow data={dataVAtaxes} feExpanded={feExpanded} />
                </tr>
                <tr className="resultsDetail">
                    <th>
                        <svg />{t("tables.results.va.savings")}
                    </th>
                    <ResultsTableRow data={dataVAsavings} feExpanded={feExpanded} />
                </tr>
            </>}
        </ResultsTableContainer>
        <div className="table-footer">{t("tables.results.confidenceLevel")}</div>
    </>
};
