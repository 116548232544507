import React from "react";

/**
 * Linear gradients for WorldMap component's legend
 */
export const LinearGradient1 = () => (
    <linearGradient id="Gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopOpacity="100%" stopColor="#FFFFFF" />
        <stop offset="100%" stopOpacity="100%" stopColor="#08306b" />
    </linearGradient>
);

export const LinearGradient2 = () => (
    <linearGradient id="Gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopOpacity="100%" stopColor="#FFFFFF" />
        <stop offset="100%" stopOpacity="100%" stopColor="#7f2704" />
    </linearGradient>
);

export const LinearGradient3 = () => (
    <linearGradient id="Gradient3" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopOpacity="100%" stopColor="#FFFFFF" />
        <stop offset="100%" stopOpacity="100%" stopColor="#00441b" />
    </linearGradient>
);
