import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: ["en"],
        preload: ["en"],
        ns: "translation",
        defaultNS: "translation",

        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    app: {
                        title: "Joint Impact Model",
                        homepage:
                            "With direct impacts being only a fraction of the total outcomes, identifying indirect impacts is a key aspect of understanding outcomes of projects. ​However, understanding and measuring these impacts is highly complex. Throughout the year, partner development finance institutions (DFIs) and Steward Redqueen have been collaborating to develop a model to measure and report on indirect impacts. The resulting Joint Impact Model (JIM), to be launched in 2020, will become publicly available for all impact investors and other interested parties. This work is a collaboration between AfDB, BIO, CDC Group, FinDev Canada, FMO and Proparco with Steward Redqueen.",
                        email:
                            "Sign up if you would like to receive updates on the JIM via email",
                        emailReceived: "Thanks for signing up!",
                        slides:
                            "On November 27 we hosted a webinar to introduce the JIM. You can access the recording below, and you can download the slides",
                        "404": {
                            title: "404",
                            description: "The page cannot be found",
                        },
                    },
                    upload: {
                        title: "Upload",
                        button: "Upload",
                        input: "Upload input file",
                        description:
                            "After the upload the Impact model will run and the output will be presented here",
                    },
                    uploadFile: {
                      agreeToLicense: "I agree to the Terms and Conditions as stated in the <1>License Agreement</1>"
                    },
                    changelog: { title: "What's new" },
                    auth: {
                        unauthorized: "You are no longer logged in",
                        forgotten: {
                            title: "Recovery",
                        },
                        login: {
                            title: "Login",
                            error: "Failed to login",
                        },
                        signup: {
                            title: "Sign up",
                            error: "Failed to sign up",
                            errorUserLimitReached: "The maximum number of accounts for your organisation has been reached.",
                            errorUnknownOrganisation:"Your organisation is not registered as a JIM member. Please contact us to get access.",
                            success:
                                "Signed up successfully. Please check your e-mail for the activation link.",
                        },
                        noAccount: "Don't have an account? Create one here",
                        backLogin: "Back to login",
                        contact: "Interested in the JIM? Request access here",
                        yesAccount: "Already have an account? Login here",
                        recoverPassword:
                            "Forgot your password? Recover it here",
                        password: {
                            label: "Password",
                            repeat: "Repeat password",
                            reqs: {
                                min: "Minimum 8 characters",
                                upper: "Minimum 1 uppercase character",
                                digits: "Minimum 1 digit",
                                match: "Passwords don't match",
                            },
                        },
                        dismiss: "Dismiss",
                        submit: "Submit",
                        email: "Email",
                        invalidEmail: "Email is invalid",
                    },
                    accountActivated: {
                        title: "User activation",
                        description: "The account was successfully activated",
                    },
                    passwordRecovery: {
                        title: "Password recovery",
                        description:
                            "You will receive an email with instructions to reset your password",
                        success: "Password reset!",
                    },
                    navbar: {
                        login: "Login",
                        logout: "Logout",
                        download: "Download model results",
                        upload: "Upload data",
                    },
                    portfolio: {
                        title: "Portfolio",
                        filters: "Filters",
                        results: "Results",
                        input: "Investment data",
                    },
                    comparison: {
                        title: "Comparison",
                        filters1: "Filters 1",
                        filters2: "Filters 2",
                        sel1: "Sel. 1",
                        sel2: "Sel. 2",
                        breakdownBySector: "Breakdown by sector"
                    },
                    filters: {
                        all: "All",
                        name: "Client name",
                        reportingyear: "Reporting Year",
                        fiscalyear: "Fiscal Year",
                        sector: "Sector",
                        country: "Country",
                        subregion: "Sub-region",
                        leastDeveloped: "Least Developed Country",
                        financialInstrument: "Financial instrument",
                        incomeClassification: "Income classification",
                        clientType: "Client type",
                        type: "Type of investment",
                        clear: "Clear all",
                        jobs: "Employment",
                        ghg: "GHG",
                        va: "Value added",
                    },
                    tables: {
                        inputs: {
                            reportingyear: "Reporting Year",
                            fiscalyear: "Fiscal Year",
                            investments: "Clients (#)",
                            amount: "Invested amount (m USD)",
                            revenue: "Revenue (m USD)",
                            power: "Power production (MWh)",
                            capital: "Outstanding amount (m USD)",
                            countries: "Countries (#)",
                            sectors: "Sectors (#)",
                        },
                        results: {
                            direct: "Direct",
                            indirect: "Supply Chain",
                            induced: "Induced",
                            power: "Power enabling",
                            fe: "Finance enabling",
                            showDetails: "Show details",
                            hideDetails: "Hide details",
                            jobs: {
                                total: "Employment (# jobs)",
                                female: "Female (# jobs)",
                                male: "Male (# jobs)",
                            },
                            ghg: {
                                total: "GHG (t CO2 eq)",
                                co2: "CO2 (t CO2 eq)",
                                non_co2: "Non-CO2 (t CO2 eq)",
                            },
                            va: {
                                total: "Value added (USD)",
                                wages: "Wages (USD)",
                                taxes: "Taxes (USD)",
                                savings: "Savings (USD)",
                            },
                            confidenceLevel: 
                                "* The asterisks correspond to the result's level of confidence, i.e. the degree of modelling used per impact result. Five stars is the highest level of confidence and one the lowest. For more detail on this, please see the User Guide.",
                        },
                    },
                    footer: {
                        version: "Joint Impact Model v. 2023.2.0.8 - ",
                        lastUpdate: "last update",
                    },
                    homepage:{
                        documentation: "Documentation"
                    }
                },
            },
        },
    });

export default i18n;
