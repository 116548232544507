import { configureStore } from "redux-starter-kit";
import rootReducer from "./reducers";

let config = {
    reducer: rootReducer,
    preloadedState: undefined,
};

const store = configureStore(config as any);

export default store;
