import { format } from "d3-format";
import config from "../config";

const formatDollars = format(",.1f");
export const fBig = (value: number) => {
    if (value > 1_000_000_000) {
        return `${formatDollars(value / 1_000_000_000)}B`;
    } else if (value > 1_000_000) {
        return `${formatDollars(value / 1_000_000)}M`;
    } else if (value > 1_000) {
        return `${formatDollars(value / 1_000)}k`;
    } else {
        return `${formatDollars(value)}`;
    }
};

export const fJobs = fBig;
export const fGHG = fBig;
export const fVA = fBig;
export const formatters = {
    [config.JOBS]: fJobs,
    [config.VA]: fVA,
    [config.GHG]: fGHG,
};
