import { combineReducers } from "redux";
import { userReducer, initialUserState } from "./userReducer";
import { dataReducer, initialDataState } from "./dataReducer";

const appReducer = combineReducers({
    user: userReducer,
    data: dataReducer,
});

const rootReducer = (state: ReduxState, action: ReduxAction) => {
    if (action.type === "LOGOUT") {
        state = { user: initialUserState, data: initialDataState };
    }

    return appReducer(state, action);
};

export default rootReducer;
