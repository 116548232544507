import { DBSchema, openDB } from "idb";
import config from "./config";

const DATABASE_NAME = "jimDB";
const DATABASE_VERSION = 1;

interface IDb extends DBSchema {
    investments: {
        value: IData;
        key: string;
        indexes: {
            byName: string;
            byCountry: string;
        };
    };
}
const regex = /( |-|\\|\/)/gi;
export const makeKeyString = (s: string) => s.replace(regex, "_");

const db = openDB<IDb>(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
        const investmentStore = db.createObjectStore("investments", {
            autoIncrement: true,
        });
        investmentStore.createIndex("byName", config.CLIENT_NAME, {
            unique: false,
        });
        investmentStore.createIndex("byCountry", config.COUNTRY, {
            unique: false,
        });
    },
});

export default db;
