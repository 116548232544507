export const sectorsList = [
    "1 pdr",
    "2 wht",
    "3 gro",
    "4 v_f",
    "5 osd",
    "6 c_b",
    "7 pfb",
    "8 ocr",
    "9 ctl",
    "10 oap",
    "11 rmk",
    "12 wol",
    "13 frs",
    "14 fsh",
    "15 coa",
    "16 oil",
    "17 gas",
    "18 oxt",
    "19 cmt",
    "20 omt",
    "21 vol",
    "22 mil",
    "23 pcr",
    "24 sgr",
    "25 ofd",
    "26 b_t",
    "27 tex",
    "28 wap",
    "29 lea",
    "30 lum",
    "31 ppp",
    "32 p_c",
    "33 chm",
    "34 bph",
    "35 rpp",
    "36 nmm",
    "37 i_s",
    "38 nfm",
    "39 fmp",
    "40 ele",
    "41 eeq",
    "42 ome",
    "43 mvh",
    "44 otn",
    "45 omf",
    "46 ely",
    "47 gdt",
    "48 wtr",
    "49 cns",
    "50 trd",
    "51 afs",
    "52 otp",
    "53 wtp",
    "54 atp",
    "55 whs",
    "56 cmn",
    "57 ofi",
    "58 ins",
    "59 rsa",
    "60 obs",
    "61 ros",
    "62 osg",
    "63 edu",
    "64 hht",
    "65 dwe",
];
export const sectorsMap: { [propsName: string]: string } = {
    "1 pdr": "Agriculture, forestry and fishing",
    "2 wht": "Agriculture, forestry and fishing",
    "3 gro": "Agriculture, forestry and fishing",
    "4 v_f": "Agriculture, forestry and fishing",
    "5 osd": "Agriculture, forestry and fishing",
    "6 c_b": "Agriculture, forestry and fishing",
    "7 pfb": "Agriculture, forestry and fishing",
    "8 ocr": "Agriculture, forestry and fishing",
    "9 ctl": "Agriculture, forestry and fishing",
    "10 oap": "Agriculture, forestry and fishing",
    "11 rmk": "Agriculture, forestry and fishing",
    "12 wol": "Agriculture, forestry and fishing",
    "13 frs": "Agriculture, forestry and fishing",
    "14 fsh": "Agriculture, forestry and fishing",
    "15 coa": "Mining and quarrying",
    "16 oil": "Mining and quarrying",
    "17 gas": "Mining and quarrying",
    "18 oxt": "Mining and quarrying",
    "19 cmt": "Manufacturing",
    "20 omt": "Manufacturing",
    "21 vol": "Manufacturing",
    "22 mil": "Manufacturing",
    "23 pcr": "Manufacturing",
    "24 sgr": "Manufacturing",
    "25 ofd": "Manufacturing",
    "26 b_t": "Manufacturing",
    "27 tex": "Manufacturing",
    "28 wap": "Manufacturing",
    "29 lea": "Manufacturing",
    "30 lum": "Manufacturing",
    "31 ppp": "Manufacturing",
    "32 p_c": "Manufacturing",
    "33 chm": "Manufacturing",
    "34 bph": "Manufacturing",
    "35 rpp": "Manufacturing",
    "36 nmm": "Manufacturing",
    "37 i_s": "Manufacturing",
    "38 nfm": "Manufacturing",
    "39 fmp": "Manufacturing",
    "40 ele": "Manufacturing",
    "41 eeq": "Manufacturing",
    "42 ome": "Manufacturing",
    "43 mvh": "Manufacturing",
    "44 otn": "Manufacturing",
    "45 omf": "Manufacturing",
    "46 ely": "Utilities",
    "47 gdt": "Utilities",
    "48 wtr": "Utilities",
    "49 cns": "Construction",
    "50 trd":
        "Wholesale and retail trade, repair of motor vehicles and motorcycles",
    "51 afs": "Accommodation",
    "52 otp": "Transport, storage and communication",
    "53 wtp": "Transport, storage and communication",
    "54 atp": "Transport, storage and communication",
    "55 whs": "Transport, storage and communication",
    "56 cmn": "Transport, storage and communication",
    "57 ofi": "Financial and insurance activities",
    "58 ins": "Financial and insurance activities",
    "59 rsa": "Real estate, business and administrative activities",
    "60 obs": "Real estate, business and administrative activities",
    "61 ros": "Public administration and defence, compulsory social security",
    "62 osg": "Public administration and defence, compulsory social security",
    "63 edu": "Public administration and defence, compulsory social security",
    "64 hht": "Public administration and defence, compulsory social security",
    "65 dwe": "Public administration and defence, compulsory social security",
};

export const treemapSectors = [
    {
        name: "Agriculture, forestry and fishing",
        children: [
            "1 pdr",
            "2 wht",
            "3 gro",
            "4 v_f",
            "5 osd",
            "6 c_b",
            "7 pfb",
            "8 ocr",
            "9 ctl",
            "10 oap",
            "11 rmk",
            "12 wol",
            "13 frs",
            "14 fsh",
        ],
    },
    {
        name: "Mining and quarrying",
        children: ["15 coa", "16 oil", "17 gas", "18 oxt"],
    },
    {
        name: "Manufacturing",
        children: [
            "19 cmt",
            "20 omt",
            "21 vol",
            "22 mil",
            "23 pcr",
            "24 sgr",
            "25 ofd",
            "26 b_t",
            "27 tex",
            "28 wap",
            "29 lea",
            "30 lum",
            "31 ppp",
            "32 p_c",
            "33 chm",
            "34 bph",
            "35 rpp",
            "36 nmm",
            "37 i_s",
            "38 nfm",
            "39 fmp",
            "40 ele",
            "41 eeq",
            "42 ome",
            "43 mvh",
            "44 otn",
            "45 omf",
        ],
    },
    { name: "Utilities", children: ["46 ely", "47 gdt", "48 wtr"] },
    { name: "Construction", children: ["49 cns"] },
    {
        name:
            "Wholesale and retail trade, repair of motor vehicles and motorcycles",
        children: ["50 trd"],
    },
    {
        name: "Accommodation",
        children: ["51 afs"],
    },
    {
        name: "Transport, storage and communication",
        children: ["52 otp", "53 wtp", "54 atp", "55 whs", "56 cmn"],
    },
    {
        name: "Financial and insurance activities",
        children: ["57 ofi", "58 ins"],
    },
    {
        name: "Real estate, business and administrative activities",
        children: ["59 rsa", "60 obs"],
    },
    {
        name: "Public administration and defence, compulsory social security",
        children: ["61 ros", "62 osg", "63 edu", "64 hht", "65 dwe"],
    },
];
