import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import config from "../config";

/**
 * Checks whether version in cookies (sent from server) is the same
 * as the version of the cached app, if not fetches latest version
 */
export default () => {
    const [hasNewVersion, setHasNewVersion] = useState(false);
    const [cookies] = useCookies();
    useEffect(() => {
        const { version } = cookies;
        if (version !== undefined && version !== config.REACT_VERSION) {
            setHasNewVersion(true);
        }
    }, [cookies]);
    const handleClick = (e: React.MouseEvent<any, any>) => {
        // reloads page with requets to server
        e.preventDefault();
        window.location.reload(true);
    };
    if (!hasNewVersion) {
        return null;
    } else {
        return (
            <div className="update-notification">
                An update is available,{" "}
                <a href="/" onClick={handleClick}>
                    click here to update
                </a>
                .
            </div>
        );
    }
};
