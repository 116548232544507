import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Attribution } from "../utils/filters";
import Select from "./Select";
interface FiltersProps {
    filters: IFilters;
    selectedFilters: IFilters;
    showYear: boolean;
    showSingleInvestment: boolean;
    setFunction: React.Dispatch<React.SetStateAction<IFilters>>;
    selection: string;
}

const Filters = (props: FiltersProps) => {
    const { filters, selectedFilters, showYear, showSingleInvestment, setFunction } = props;
    const { t } = useTranslation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFunction({ ...filters, ...selectedFilters, attribution: [e.target.value] });
    };

    // if results don't include attribution data hide filters
    const showAttribution = useSelector(
        (state: ReduxState) => state.data.showAttribution,
    );

    const selectFilter = (name: string, value: string[]) => {
        const newFilters = { ...selectedFilters }
        newFilters[name] = value;
        setFunction(newFilters);
    }
    const committedDisabled = filters.attribution.indexOf(Attribution.Com) === -1;
    const outstandingDisabled = filters.attribution.indexOf(Attribution.Out) === -1;
    return (
        <div className="input-filters-col">
            {showAttribution && (
                <div className="attribution-radios">
                    <label>Attribution</label>
                    <label>
                        <input
                            type="radio"
                            name={`attribution-${props.selection}`}
                            value={"No attribution"}
                            checked={selectedFilters.attribution[0] === Attribution.No}
                            onChange={handleChange}
                        ></input>
                        No attribution
                    </label>
                    <label>
                        <input
                            type="radio"
                            name={`attribution-${props.selection}`}
                            value={"Committed"}
                            checked={selectedFilters.attribution[0] === Attribution.Com}
                            onChange={handleChange}
                            disabled={committedDisabled}
                        ></input>
                        Committed{committedDisabled && " (no data)"}
                    </label>
                    <label>
                        <input
                            type="radio"
                            name={`attribution-${props.selection}`}
                            value={"Outstanding"}
                            checked={selectedFilters.attribution[0] === Attribution.Out}
                            onChange={handleChange}
                            disabled={outstandingDisabled}
                        ></input>
                        Outstanding{outstandingDisabled && " (no data)"}
                    </label>
                </div>
            )}
            {showSingleInvestment && (
                <Select
                    label={t("filters.name")}
                    name={"ClientNameCode"}
                    filter={filters.ClientNameCode}
                    selectedFilter={selectedFilters.ClientNameCode}
                    setFunction={selectFilter}
                />
            )}
            {showYear && (
                <>
                    <Select
                        label={t("filters.reportingyear")}
                        name={"ReportingYear"}
                        filter={filters.ReportingYear}
                        selectedFilter={selectedFilters.ReportingYear}
                        setFunction={selectFilter}
                        multiple={false}
                    />
                    <Select
                        label={t("filters.fiscalyear")}
                        name={"FiscalYear"}
                        filter={filters.FiscalYear}
                        selectedFilter={selectedFilters.FiscalYear}
                        setFunction={selectFilter}
                        multiple={false}
                    />
                </>
            )}
            <Select
                label={t("filters.sector")}
                name={"EconomicActivity"}
                filter={filters.EconomicActivity}
                selectedFilter={selectedFilters.EconomicActivity}
                setFunction={selectFilter}
            />
            <Select
                label={t("filters.country")}
                name={"Country"}
                filter={filters.Country}
                selectedFilter={selectedFilters.Country}
                setFunction={selectFilter}
            />
            <Select
                label={t("filters.subregion")}
                name={"SubRegionName"}
                filter={filters.SubRegionName}
                selectedFilter={selectedFilters.SubRegionName}
                setFunction={selectFilter}
            />
            <Select
                label={t("filters.incomeClassification")}
                name={"IncomeClassification"}
                filter={filters.IncomeClassification}
                selectedFilter={selectedFilters.IncomeClassification}
                setFunction={selectFilter}
            />
            <Select
                label={t("filters.clientType")}
                name={"ClientType"}
                filter={filters.ClientType}
                selectedFilter={selectedFilters.ClientType}
                setFunction={selectFilter}
            />
            <Select
                label={t("filters.leastDeveloped")}
                name={"LeastDeveloped"}
                filter={filters.LeastDeveloped}
                selectedFilter={selectedFilters.LeastDeveloped}
                setFunction={selectFilter}
            />
        </div>
    );
};

Filters.defaultProps = {
    showYear: true,
    showSingleInvestment: true,
};

export default Filters;
