import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const ListPasswordRequirements = (props: { arr: string[] }) => {
    const { t } = useTranslation();
    const { arr } = props;
    return (
        <ul>
            {arr.includes("min") && <li>{t("auth.password.reqs.min")}</li>}
            {arr.includes("uppercase") && (
                <li>{t("auth.password.reqs.upper")}</li>
            )}
            {arr.includes("digits") && (
                <li>{t("auth.password.reqs.digits")}</li>
            )}
            {arr.includes("match") && <li>{t("auth.password.reqs.match")}</li>}
        </ul>
    );
};

const PasswordField = (props: PasswordFieldProps) => {
    const { password } = props;
    const errorPassword =
        !password.isValid.isValid &&
        password.value.length !== 0 &&
        !password.isTyping;

    const classesPassword = classnames(
        "u-full-width",
        errorPassword ? "error" : null,
    );

    return (
        <>
            <div className="input-label-group">
                <label
                    htmlFor="password"
                    className={errorPassword ? "error" : ""}
                >
                    {props.label}
                </label>
                {errorPassword && (
                    <small className="error">
                        <ListPasswordRequirements
                            arr={password.isValid.requirements!}
                        />
                    </small>
                )}
            </div>
            <input
                className={classesPassword}
                type="password"
                required={true}
                name={props.name}
                placeholder={props.label}
                onChange={props.handleInputChange}
                onBlur={
                    props.handleInputBlur as (
                        e: React.FocusEvent<HTMLInputElement>,
                    ) => void
                }
            />
        </>
    );
};

export default PasswordField;
