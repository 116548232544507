import React from "react";
import { useDispatch } from "react-redux";
import config from "../config";
import { useTranslation } from "react-i18next";

const GlobalFilters = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <form className="global-filters">
            <label className="radio">
                <input
                    type="radio"
                    name="globalFilter"
                    onChange={e =>
                        dispatch({
                            type: "FILTER_DATA_GLOBAL",
                            payload: e.currentTarget.value,
                        })
                    }
                    defaultChecked={true}
                    value={config.JOBS}
                    className="hidden"
                />
                <span className="label-body" />
                {t("filters.jobs")}
            </label>
            <label className="radio">
                <input
                    type="radio"
                    name="globalFilter"
                    onChange={e =>
                        dispatch({
                            type: "FILTER_DATA_GLOBAL",
                            payload: e.currentTarget.value,
                        })
                    }
                    value={config.GHG}
                    className="hidden"
                />
                <span className="label-body" />
                {t("filters.ghg")}
            </label>
            <label className="radio">
                <input
                    type="radio"
                    name="globalFilter"
                    onChange={e =>
                        dispatch({
                            type: "FILTER_DATA_GLOBAL",
                            payload: e.currentTarget.value,
                        })
                    }
                    value={config.VA}
                    className="hidden"
                />
                <span className="label-body" />
                {t("filters.va")}
            </label>
        </form>
    );
};

export default GlobalFilters;
