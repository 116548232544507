import { max } from "d3-array";
import { createReducer } from "redux-starter-kit";
import config from "../config";
import db from "../db";

export const shouldFilter = (filters: string[]) => !(filters[0] === "All");

export const getLatestFiscalYear = (data: IData[]): number =>
    max(data, (d: IData) => d[config.FISCAL_YEAR]);

export const getLatestReportingYear = (data: IData[]): number =>
    max(data, (d: IData) => d[config.REPORTING_YEAR]);

export const initialDataState: ReduxDataState = {
    isUploading: false,
    isThereData: null,
    showAttribution: false,
    impactCategory: config.JOBS,
    errorUploading: null,
};

const addInvestmentsToDb = async (investments: IData[]) => {
    // clear indexeddb and adds new investments
    (await db).clear("investments");
    for (let investment of investments) {
        (await db).put("investments", investment);
    }
};

const hasAttrReducer = (hasAttr: boolean, value: IData) => {
    return hasAttr || (value.AttributionShareCommitted !== undefined || value.AttributionShareOutstanding !== undefined);
}

export const dataReducer = createReducer(initialDataState, {
    UPLOAD: (state: ReduxDataState) => {
        state.isUploading = true;
        state.isThereData = false;
    },
    RESET_UPLOAD_ERROR: (state: ReduxDataState) => {
        state.errorUploading = null;
    },
    DATA_RECEIVED: (state: ReduxDataState, action: { payload: IData[] }) => {
        state.errorUploading = null;
        addInvestmentsToDb(action.payload);
        state.isThereData = true;
        state.isUploading = false;
        //console.log(action.payload);
        state.showAttribution = action.payload.reduce(hasAttrReducer, false);
    },
    FAILED_UPLOAD: (state: ReduxDataState, action) => {
        state.isUploading = false;
        state.errorUploading = action.payload;
    },
    // filter data by type (jobs, ghg, va)
    FILTER_DATA_GLOBAL: (state: ReduxDataState, action) => {
        const { payload } = action;
        state.impactCategory = payload;
    },
    SHOW_ATTRIBUTION: (state: ReduxDataState, action) => {
        state.showAttribution = action.payload;
    },
    IS_THERE_DATA: (state: ReduxDataState, action) => {
        state.isThereData = action.payload;
    },
});
