import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import GHGIcon from "../icons/GHG";
import VAIcon from "../icons/trendingUp";
import JobsIcon from "../icons/users";
import {
    prepareData,
    ResultsTableContainer,
    ResultsTableRow,
} from "./ResultsTable";
interface ResultsTableProps {
    data1: IData[];
    data2: IData[];
    label1: string;
    label2: string;
}

export default (props: ResultsTableProps) => {
    const { t } = useTranslation();
    const dataJobs1 = prepareData(props.data1, config.JOBS, null);
    const dataGHG1 = prepareData(props.data1, config.GHG, null);
    const dataVA1 = prepareData(props.data1, config.VA, null);

    const dataJobs2 = prepareData(props.data2, config.JOBS, null);
    const dataGHG2 = prepareData(props.data2, config.GHG, null);
    const dataVA2 = prepareData(props.data2, config.VA, null);

    return (
        <ResultsTableContainer>
            <tr>
                <th colSpan={11}>
                    <JobsIcon size={15} /> {t("tables.results.jobs.total")}
                </th>
            </tr>
            <tr>
                <th>{props.label1}</th>
                <ResultsTableRow data={dataJobs1} />
            </tr>
            <tr>
                <th>{props.label2}</th>
                <ResultsTableRow data={dataJobs2} />
            </tr>
            <tr>
                <th colSpan={11}>
                    <GHGIcon size={15} /> {t("tables.results.ghg.total")}
                </th>
            </tr>
            <tr>
                <th>{props.label1}</th>
                <ResultsTableRow data={dataGHG1} />
            </tr>
            <tr>
                <th>{props.label2}</th>
                <ResultsTableRow data={dataGHG2} />
            </tr>
            <tr>
                <th colSpan={11}>
                    <VAIcon size={15} /> {t("tables.results.va.total")}
                </th>
            </tr>
            <tr>
                <th>{props.label1}</th>
                <ResultsTableRow data={dataVA1} />
            </tr>
            <tr>
                <th>{props.label2}</th>
                <ResultsTableRow data={dataVA2} />
            </tr>
        </ResultsTableContainer>
    );
};
