import { format } from "d3-format";
import { scaleOrdinal } from "d3-scale";
import config from "../config";

export const color = scaleOrdinal().range([
    "#EFB605",
    "#E58903",
    "#E01A25",
    "#C20049",
    "#991C71",
    "#66489F",
    "#2074A0",
    "#10A66E",
    "#7EB852",
]);
export const innerPadding = 0.15;
export const cornerRadius = 0.1;
export const padAngle = 0.005;
export const duration = 1000;
export const f = format(",.0f");
export const impactTypes = [
    config.subtypes.DIRECT,
    config.subtypes.INDIRECT,
    config.subtypes.INDUCED,
    config.typesImpact.POWER,
    config.typesImpact.FE,
];
