import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/**
 * Footer that displays JIM backend version, update before deployment
 */
export default () => {
    const { t } = useTranslation();
    const today = new Date(2023, 5, 14); //date of month is 0 based?!
    return (
        <footer>
            {t("footer.version")}
            <Link to="/changelog">
                {t("footer.lastUpdate")} {today.toLocaleDateString()}
            </Link>
        </footer>
    );
};
