import React from "react";

export default (props: SVGIcon) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={String(props.size)}
        height={String(props.size)}
        viewBox={String([0, 0, 24, 20])}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
        <polyline points="16 17 21 12 16 7" />
        <line x1="21" y1="12" x2="9" y2="12" />
    </svg>
);
