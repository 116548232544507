import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const EmailField = (props: EmailFieldProps) => {
    const { t } = useTranslation();
    const { email, label } = props;
    const errorEmail =
        !email.isValid.isValid && !email.isTyping && email.value.length !== 0;
    const classesEmail = classnames(
        "u-full-width",
        errorEmail ? "error" : null,
    );

    return (
        <>
            <div className="input-label-group">
                {label && (
                    <label
                        htmlFor="email"
                        className={errorEmail ? "error" : ""}
                    >
                        {t("auth.email")}
                    </label>
                )}
                {errorEmail && (
                    <small className="error">{t("auth.invalidEmail")}</small>
                )}
            </div>
            <input
                className={classesEmail}
                type="email"
                name="email"
                required={true}
                placeholder="Email"
                onChange={props.handleInputChange}
                onBlur={
                    props.handleInputBlur as (
                        e: React.FocusEvent<HTMLInputElement>,
                    ) => void
                }
            />
        </>
    );
};

EmailField.defaultProps = {
    label: true,
};

export default EmailField;
