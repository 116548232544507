import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PortfolioView from "./Portfolio";
import Upload from "./Upload";
import paths from "../paths";
import Authentication from "./Authentication";

const Dashboard = () => {
    const data = useSelector((state: ReduxState) => state.data.isThereData);
    const loggedIn = useSelector((state: ReduxState) => state.user.loggedIn);
    if(data === null)
    {
        return ( <p>Loading</p> );
    }
    return (
        <Switch>
            <Route exact path={paths.dashboard}>
                {loggedIn ? 
                    data ? 
                        <PortfolioView /> : 
                        <Upload /> :
                    <Authentication />
                }
            </Route>
        </Switch>
    );
};

export default Dashboard;
