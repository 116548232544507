/**
 * Returns the elements of `arr1` that do not appear in `arr2`.
 * @param arr1 is an array
 * @param arr2 is an array
 */
export function difference<T>(arr1: T[], arr2: T[]) {
    return arr1.filter((x: T) => !arr2.includes(x));
}

/**
 * Returns an array of the elements that appear in both `arr1` and `arr2`.
 * @param arr1 is an array
 * @param arr2 is an array
 */
export function intersection<T>(arr1: T[], arr2: T[]) {
    return arr1.filter(x => arr2.includes(x));
}

/**
 * Removes the elements that appear in both `arr1` and `arr2`.
 * @param arr1 is an array
 * @param arr2 is an array
 */
export function noIntersection<T>(arr1: T[], arr2: T[]) {
    return arr1
        .filter(x => !arr2.includes(x))
        .concat(arr2.filter(x => !arr1.includes(x)));
}
