import React from "react";
import classnames from "classnames";

export default (props: {
    alignItemsCenter?: boolean;
    children: JSX.Element[] | JSX.Element;
    classes?: string;
}) => {
    const classes = classnames(
        "row justify-content-center",
        props.classes,
        props.alignItemsCenter ? "align-items-center" : null,
    );
    return <div className={classes}>{props.children}</div>;
};
