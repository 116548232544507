import React from "react";

export default (props: SVGIcon) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={String(props.size)}
        height={String(props.size)}
        viewBox={String([0, 0, 24, 20])}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="download-icon"
        onClick={props.onClick}
    >
        <title>Download</title>
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
        <polyline points="7 10 12 15 17 10"></polyline>
        <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
);
