import React from "react";

interface BoxProps {
    children: JSX.Element | string;
}

const BoxErrorTable = (props: {data: any}) => (
    <div className={"box error"}>
        <div className={"box error"}>
            {props.data.msg}
        </div>
        <tr>
            { props.data.data &&
                <tr>
                    <th>
                        Error
                    </th>
                    <th>
                        Message
                    </th>
                    <th>
                        Trace
                    </th>
                </tr>
            }
            { props.data.data && props.data.data.map( (row: any) => {
                return (
                    <tr>
                        <td>{row[0]}</td>
                        <td>{row[1]}</td>
                        <td>{row[2]}</td>
                    </tr>
                )
            }) }
        </tr>
    </div>
);

const BoxError = (props: BoxProps) => ( 
    <div className={"box error"}>{props.children}</div> 
);

const BoxSuccess = (props: BoxProps) => (
    <div className={"box success"}>{props.children}</div>
);

export { BoxError, BoxSuccess, BoxErrorTable };
