import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import normalizeEmail from "validator/lib/normalizeEmail";
import PasswordField from "../components/PasswordField";
import EmailField from "../components/EmailField";
import LoadingSpinner from "./LoadingSpinner";
import { BoxError, BoxSuccess } from "../components/Box";
import Row from "../components/Row";
import { validateEmail, validatePassword } from "../utils/validators";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import paths from "../paths";

const Registration = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState({
        value: "",
        isTyping: false,
        isValid: { isValid: false },
    });
    const [password, setPassword] = useState({
        value: "",
        isTyping: false,
        isValid: { isValid: false, requirements: [""] },
    });
    const [password2, setPassword2] = useState({
        value: "",
        isTyping: false,
        isValid: { isValid: false, requirements: [""] },
    });
    const [isLogin, setIsLogin] = useState(true);
    const [hasForgotten, setForgot] = useState(false);

    useEffect(() => {
        document.title = isLogin
            ? t("auth.login.title")
            : hasForgotten 
                ? t("auth.forgotten.title") 
                : t("auth.signup.title");
    });

    const [loginError, setLoginError] = useState(null);
    const [registrationError, setRegistrationError] = useState(null);
    const [signedUp, setSignedUp] = useState(false);
    const [fetching, setFetching] = useState(false);

    const passwordsAreValid = hasForgotten
        ? true
        : isLogin
        ? password.value !== ""
        : password.isValid.isValid &&
          password2.isValid.isValid &&
          password.value === password2.value;
    const enableButtons = email.isValid && passwordsAreValid;

    const submitSignUp = () => {
        setFetching(true);
        setSignedUp(false);
        setLoginError(null);
        setRegistrationError(null);
        axios
            .post("/api/users", {
                email: normalizeEmail(email.value),
                password: password.value,
                password2: password2.value,
            })
            .then(res => {
                setFetching(false);
                setSignedUp(true);
                setIsLogin(true);
            })
            .catch(err => {
                console.error(err);
                setRegistrationError(err.response.data.type);
                setFetching(false);
            });
    };

    const history = useHistory();
    const submitLogin = () => {
        setFetching(true);
        setSignedUp(false);
        setLoginError(null);
        setRegistrationError(null);
        axios
            .post("/api/token/auth", {
                email: normalizeEmail(email.value),
                password: password.value,
            })
            .then(res => {
                dispatch({ type: "LOGIN" });
                setFetching(false);
                history.push(paths.dashboard);
            })
            .catch(err => {
                console.error(err);
                setLoginError(err);
                setFetching(false);
            });
    };

    const submitRecovery = () => {
        setFetching(true);
        setLoginError(null);
        setRegistrationError(null);
        axios
            .post("/api/users/recover", {
                email: normalizeEmail(email.value),
            })
            .then(res => {
                dispatch({ type: "PASSWORD_RECOVERY" });
                setFetching(false);
                history.push("/reset-password");
            })
            .catch(err => {
                console.error(err);
                setLoginError(err);
                setFetching(false);
            });
    };

    return (
        <>
            {fetching && <LoadingSpinner />}
            <div className="container">
                <Row>
                    <div className="col-12 col-md-5 App">
                        <h1>
                            {isLogin
                                ? t("auth.login.title")
                                : hasForgotten 
                                    ? t("auth.forgotten.title") 
                                    : t("auth.signup.title")}
                        </h1>
                        {registrationError !== null && (
                            <>
                                <BoxError>{t("auth.signup.error")}</BoxError>
                                <button onClick={() => setRegistrationError(null)}>{t("auth.dismiss")}</button> 
                            </> 
                        )}
                        {registrationError === "UserLimitReached" && (
                                <BoxError>{t("auth.signup.errorUserLimitReached")}</BoxError>
                        )}
                        {registrationError === "UnknownOrganisation" && (
                                <BoxError>{t("auth.signup.errorUnknownOrganisation")}</BoxError>
                        )}
                        {signedUp && (
                            <BoxSuccess>{t("auth.signup.success")}</BoxSuccess>
                        )}
                        {loginError !== null && (
                            <> 
                                <BoxError>{t("auth.login.error")}</BoxError> 
                                <button onClick={() => setLoginError(null)}>{t("auth.dismiss")}</button> 
                            </> 
                        )}

                        <EmailField
                            email={email}
                            handleInputChange={e =>
                                setEmail({
                                    value: e.currentTarget.value,
                                    isTyping: true,
                                    isValid: email.isValid,
                                })
                            }
                            handleInputBlur={e =>
                                setEmail({
                                    value: e.currentTarget.value,
                                    isTyping: false,
                                    isValid: validateEmail(
                                        e.currentTarget.value,
                                    ),
                                })
                            }
                        />
                        {!hasForgotten && (
                            <PasswordField
                                name="password"
                                password={password}
                                handleInputChange={e =>
                                    setPassword({
                                        value: e.currentTarget.value,
                                        isTyping: true,
                                        isValid: isLogin
                                            ? {
                                                  isValid: true,
                                                  requirements: [],
                                              }
                                            : password.isValid,
                                    })
                                }
                                handleInputBlur={e =>
                                    setPassword({
                                        value: e.currentTarget.value,
                                        isTyping: false,
                                        isValid: isLogin
                                            ? {
                                                  isValid: true,
                                                  requirements: [],
                                              }
                                            : validatePassword(
                                                  e.currentTarget.value,
                                              ),
                                    })
                                }
                                label={t("auth.password.label")}
                            />
                        )}
                        {!isLogin && !hasForgotten && (
                            <PasswordField
                                name="password2"
                                password={password2}
                                handleInputChange={e =>
                                    setPassword2({
                                        value: e.currentTarget.value,
                                        isTyping: true,
                                        isValid: isLogin
                                            ? {
                                                  isValid: true,
                                                  requirements: [],
                                              }
                                            : password2.isValid,
                                    })
                                }
                                handleInputBlur={e => {
                                    const value = e.currentTarget.value;
                                    const isValid = isLogin
                                        ? { isValid: true, requirements: [] }
                                        : validatePassword(value);
                                    setPassword2({
                                        value: value,
                                        isTyping: false,
                                        isValid:
                                            value === password.value
                                                ? isValid
                                                : {
                                                      ...isValid,
                                                      requirements: [
                                                          ...isValid.requirements,
                                                          "match",
                                                      ],
                                                  },
                                    });
                                }}
                                label={t("auth.password.repeat")}
                            />
                        )}
                        {!isLogin && !hasForgotten && (
                        <p>
                            By clicking on the ‘submit’ button and signing up for the JIM, users agree to the Terms and Conditions as stated in the <a href={paths.licenseAgreement} target="_blank" rel='noopener noreferrer'>License Agreement</a>
                        </p>
                        )}

                        <input
                            className="u-full-width button-primary"
                            disabled={!enableButtons}
                            onClick={
                                hasForgotten
                                    ? submitRecovery
                                    : isLogin
                                    ? submitLogin
                                    : submitSignUp
                            }
                            type="button"
                            value={t("auth.submit") as string}
                        />
                        <button 
                            className="link u-full-width" 
                            onClick={e => 
                            { 
                                e.preventDefault(); 
                                window.location.assign(paths.wixContact) 
                            }} 
                        > 
                            {t("auth.contact")} 
                        </button>
                        {!hasForgotten && <button
                            className="link u-full-width"
                            onClick={e => {
                                e.preventDefault();
                                setIsLogin(!isLogin);
                            }}
                        >
                            {isLogin
                                ? t("auth.noAccount")
                                : t("auth.yesAccount")}
                        </button>}
                       
                        <button
                            className="link u-full-width"
                            onClick={e => { 
                                    e.preventDefault(); 
                                    setForgot(!hasForgotten);
                                    setIsLogin(!isLogin)
                                }} 
                            > 
                                {hasForgotten 
                                    ? t("auth.backLogin") 
                                    : t("auth.recoverPassword")} 
                        </button>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default Registration;
