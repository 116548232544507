import React from "react";

interface ILegendBarProps {
    y: number;
    value: string;
    barWidth: number;
    barHeight: number;
    barX: number;
    fill: string;
}

/**
 * This component renders a legend bar and its range as text
 */
export default (props: ILegendBarProps) => {
    const textYShift = 12;
    return (
        <g>
            <text
                x={props.barX - 20}
                y={props.y + textYShift}
                className="world-map"
            >
                0
            </text>
            <text
                x={props.barX + props.barWidth + 10}
                y={props.y + textYShift}
                className="world-map"
            >
                {props.value}
            </text>
            <rect
                fill={props.fill}
                width={props.barWidth}
                height={props.barHeight}
                x={props.barX}
                y={props.y}
            />
        </g>
    );
};
