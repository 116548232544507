import axios from "axios";
import React from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UpdatePrompt from "./components/UpdatePrompt";
import dbPromise from "./db";
import paths from "./paths";
import { useMountEffect } from "./utils/useMountEffect";
import {
    Authentication,
    ComparisonView,
    Dashboard,
    Homepage,
    Navbar,
    PasswordRecoveryEmailSent,
    Upload,
} from "./views";
import ActivatedAccount from "./views/ActivatedAccount";
import Changelog from "./views/Changelog";
import ResetPassword from "./views/ResetPassword";

const checkIfDataInDB = async () => {
    const db = await dbPromise;
    const cursor = await db
        .transaction("investments", "readonly")
        .objectStore("investments")
        .openCursor();
    if (cursor === null) return { isThereData: false, showAttribution: false };
    if (
        cursor.value.AttributionShareCommitted !== undefined ||
        cursor.value.AttributionShareOutstanding !== undefined
    )
        return { isThereData: true, showAttribution: true };
    return { isThereData: true, showAttribution: false };
};

const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [cookies] = useCookies();
    useMountEffect(() => {
        document.title = t("app.title");
        const { csrf_refresh_token } = cookies;
        if (csrf_refresh_token) {
            // if cookies are present, login to webapp
            dispatch({ type: "LOGIN" });
            // refresh token
            axios
                .post(
                    "/api/token/refresh",
                    {},
                    { headers: { "X-CSRF-TOKEN": csrf_refresh_token } },
                )
                .then()
                .catch(e => {
                    console.error(e);
                    dispatch({ type: "UNAUTHORIZED" });
                });
        }
        if (!window.indexedDB) {
            alert(
                "Your browser does not support IndexedDB, you may incur issues",
            );
        }
    });
    useMountEffect(() => {
        checkIfDataInDB()
            .then(isThereData => {
                dispatch({
                    type: "IS_THERE_DATA",
                    payload: isThereData.isThereData,
                });
                dispatch({
                    type: "SHOW_ATTRIBUTION",
                    payload: isThereData.showAttribution,
                });
            })
            .catch(e => console.error(e));
    });

    return (
        <Router>
            <UpdatePrompt />
            <header>
                <Navbar />
            </header>
            <div className="container-fluid app">
                <Switch>
                    <Route
                        path="/account-activated"
                        component={ActivatedAccount}
                    />
                    <Route path="/auth" component={Authentication} />
                    <Route
                        exact
                        path="/reset-password"
                        component={PasswordRecoveryEmailSent}
                    />
                    <Route
                        path="/reset-password/:token"
                        component={ResetPassword}
                    />
                    <Route exact path="/" component={Homepage} />
                    <Route path={paths.dashboard} component={Dashboard} />
                    <Route
                        path="/comparison"
                        component={ComparisonView}
                    />
                    <Route path="/upload" component={Upload} />
                    <Route path="/changelog" component={Changelog} />
                    <Route component={Homepage} />
                </Switch>
            </div>
        </Router>
    );
};

export default App;
