import { nest } from "d3-collection";
import { hierarchy, treemap } from "d3-hierarchy";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { select } from "d3-selection";
import "d3-transition/src/selection";
import React, { createRef } from "react";
import { v4 } from "uuid";
import config from "../config";
import { formatters } from "../utils/formatters";
import { reduceTotal } from "../utils/reducers";
import { impactTypes } from "./constants";
import { treemapSectors } from "./sectorsMap";

interface TreemapData {
    name: string;
    value?: number;
    children: TreemapData[];
}

const subtypesBackward = [
    config.subtypes.DIRECT,
    config.subtypes.INDIRECT,
    config.subtypes.INDUCED,
];

const prepData = (data: IData[]): TreemapData => {
    return {
        name: "All sectors",
        children: treemapSectors.map(sector => {
            return {
                name: sector.name,
                children: impactTypes.map(impactType => {
                    return {
                        name: impactType,
                        children: sector.children.map(subsector => {
                            return {
                                name: subsector,
                                value: data
                                    .filter(d =>
                                        subtypesBackward.includes(impactType)
                                            ? d.SubScope === impactType &&
                                              (d.Scope ===
                                                  config.typesImpact
                                                      .BACK_PERM ||
                                                  d.Scope ===
                                                      config.typesImpact
                                                          .BACK_TEMP)
                                            : d.Scope === impactType,
                                    )
                                    .reduce(reduceTotal(subsector), 0),
                            };
                        }),
                    };
                }),
            };
        }) as TreemapData[],
    };
};

interface TreemapProps {
    width: number;
    height: number;
    data: IData[];
    impactCategory: string;
    // d3 color scheme
    colorScheme: any;
}

interface TreemapState {
    parents: any[];
    rootData: TreemapData | null;
}

class Treemap extends React.Component<TreemapProps, TreemapState> {
    ref: React.RefObject<any>;
    treemap: any;
    x: any;
    y: any;
    color: any;
    constructor(props: TreemapProps) {
        super(props);
        this.ref = createRef();
        this.state = { parents: [], rootData: null };
        const { width, height } = this.props;
        this.color = scaleOrdinal(this.props.colorScheme);

        this.treemap = (data: TreemapData) =>
            treemap()
                .size([width, height])
                .paddingOuter(3)
                .paddingTop(19)
                .paddingInner(1)
                .round(true)(
                hierarchy(data)
                    .sum(d => d.value!)
                    .sort((a, b) => b.value! - a.value!),
            );
        this.x = scaleLinear()
            .domain([0, width])
            .range([0, width]);

        this.y = scaleLinear()
            .domain([0, height])
            .range([50, height]);

        this.addParentToState = this.addParentToState.bind(this);
        this.removeParentFromState = this.removeParentFromState.bind(this);
    }

    addParentToState(parent: any) {
        const parents = this.state.parents.concat(parent);
        this.setState({ parents: parents });
    }

    removeParentFromState() {
        let { parents } = this.state;
        const parent = parents.pop();
        this.setState({ parents: parents });
        return parent;
    }

    makeNestedTreemap(data: TreemapData) {
        const maxDepth = 1;
        const root = this.treemap(data);
        select(this.ref.current)
            .selectAll("*")
            .remove();

        const node = select(this.ref.current)
            .selectAll("g")
            .data(
                nest()
                    .key((d: any) => d.height)
                    .entries(root.descendants()),
            )
            .join("g")
            .selectAll("g")
            .data(d => d.values)
            .join("g")
            .attr("transform", (d: any) => `translate(${d.x0},${d.y0})`);

        node.append("title").text(
            (d: any) =>
                `${d
                    .ancestors()
                    .reverse()
                    .map((d: any) => d.data.name)
                    .join(" / ")}\n${formatters[this.props.impactCategory](
                    d.value,
                )}`,
        );

        node.append("rect")
            .attr("id", (d: any) => (d.nodeUid = v4()))
            .attr("class", "tree-node")
            .on("click", (d: any) => {
                if (d.depth === 0) {
                    const parent = this.removeParentFromState();
                    if (parent === undefined && this.state.rootData !== null) {
                        this.makeNestedTreemap(this.state.rootData);
                    } else this.makeNestedTreemap(parent.data);
                } else if (d.height > 0) {
                    this.addParentToState(d.parent);
                    this.makeNestedTreemap(d.data);
                }
            })
            .attr("fill", (d: any) => {
                switch (d.data.name) {
                    // case config.subtypes.DIRECT:
                    // case config.subtypes.INDIRECT:
                    // case config.subtypes.INDUCED:
                    //     return String(colorLabels(d.data.name));
                    default:
                        return String(this.color(d.data.name));
                }
            })
            .transition()
            .duration(500)
            .attr("width", (d: any) =>
                d.depth > maxDepth ? null : d.x1 - d.x0,
            )
            .attr("height", (d: any) =>
                d.depth > maxDepth ? null : d.y1 - d.y0,
            );

        node.append("clipPath")
            .attr("id", (d: any) => (d.clipUid = v4()))
            .append("use")
            .attr("xlink:href", (d: any) => d.nodeUid.href);

        node.append("text")
            .attr("clip-path", (d: any) => d.clipUid)
            .selectAll("tspan")
            .data((d: any) =>
                d.depth > maxDepth
                    ? []
                    : d.data.name
                          .split(/(?=[A-Z][^A-Z])/g)
                          .concat(
                              formatters[this.props.impactCategory](d.value),
                          ),
            )
            .join("tspan")
            .attr("fill-opacity", (d, i, nodes) =>
                i === nodes.length - 1 ? 0.7 : null,
            )
            .text(d => String(d));

        node.append("text")
            .attr("class", "tree-back-btn")
            .selectAll("tspan")
            .data((d: any) => d.depth)
            .join("tspan")
            .text(d => String(d));

        node.filter((d: any) => d.children)
            .selectAll("tspan")
            .attr("dx", 3)
            .attr("y", 13);

        // last child || deepest element has newline between name and value
        node.filter((d: any) => !d.children || d.depth === maxDepth)
            .selectAll("tspan")
            .attr("x", 3)
            .attr(
                "y",
                (d, i, nodes) =>
                    `${(i === nodes.length - 1 ? 1 : 0) * 0.3 +
                        1.1 +
                        i * 0.9}em`,
            );
    }
    //TODO add year to comparison view
    //TODO group bar chart by type of impact
    makeSVG(data: TreemapData) {
        this.makeNestedTreemap(data);
    }

    componentDidMount() {
        // initialize svg
        if (this.props.data !== null) {
            const data = prepData(this.props.data);
            this.setState({ rootData: data });
            this.makeSVG(data);
        }
    }

    componentDidUpdate(prevProps: TreemapProps) {
        if (this.props.data !== prevProps.data) {
            const data = prepData(this.props.data);
            this.setState({ rootData: data });
            this.makeSVG(data);
        }
    }

    render() {
        return (
            <svg
                viewBox={String([0, 0, this.props.width, this.props.height])}
                preserveAspectRatio={"xMinYMin meet"}
                className="svg-content"
                ref={this.ref}
                fontSize={"8px"}
            />
        );
    }
}

export default Treemap;
