import axios from "axios";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BoxError, BoxSuccess } from "../components/Box";
import PasswordField from "../components/PasswordField";
import Row from "../components/Row";
import { useMountEffect } from "../utils/useMountEffect";
import { validatePassword } from "../utils/validators";
import LoadingSpinner from "./LoadingSpinner";

const Recover = () => {
    const { t } = useTranslation();
    useMountEffect(() => {
        document.title = t("passwordRecovery.title");
    });
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState({
        value: "",
        isTyping: false,
        isValid: { isValid: false, requirements: [""] },
    });
    const [password2, setPassword2] = useState({
        value: "",
        isTyping: false,
        isValid: { isValid: false, requirements: [""] },
    });
    const enableButtons = useMemo(
        () => password.isValid.isValid && password2.isValid.isValid && !success,
        [password, password2, success],
    );
    const { token } = useParams();
    const submit = () => {
        setFetching(true);
        setError(null);
        axios
            .post(`/api/users/reset-password/${token}`, {
                password: password.value,
                password2: password2.value,
            })
            .then(res => {
                setFetching(false);
                setSuccess(true);
            })
            .catch(err => {
                console.error(err);
                setError(err.response.data.msg);
                setFetching(false);
            });
    };

    return (
        <>
            {fetching && <LoadingSpinner />}
            <div className="container">
                <Row>
                    <div className="col-12 col-md-5 App">
                        <h1>{t("passwordRecovery.title")}</h1>
                        {success && (
                            <BoxSuccess>
                                {t("passwordRecovery.success")}
                            </BoxSuccess>
                        )}
                        {error !== null && <BoxError>{error}</BoxError>}
                        <PasswordField
                            name="password"
                            password={password}
                            handleInputChange={e =>
                                setPassword({
                                    value: e.currentTarget.value,
                                    isTyping: true,
                                    isValid: password.isValid,
                                })
                            }
                            handleInputBlur={e =>
                                setPassword({
                                    value: e.currentTarget.value,
                                    isTyping: false,
                                    isValid: validatePassword(
                                        e.currentTarget.value,
                                    ),
                                })
                            }
                            label={t("auth.password.label")}
                        />
                        <PasswordField
                            name="password2"
                            password={password2}
                            handleInputChange={e =>
                                setPassword2({
                                    value: e.currentTarget.value,
                                    isTyping: true,
                                    isValid: password2.isValid,
                                })
                            }
                            handleInputBlur={e => {
                                const value = e.currentTarget.value;
                                const isValid = validatePassword(value);
                                setPassword2({
                                    value: value,
                                    isTyping: false,
                                    isValid:
                                        value === password.value
                                            ? isValid
                                            : {
                                                  ...isValid,
                                                  requirements: [
                                                      ...isValid.requirements,
                                                      "match",
                                                  ],
                                              },
                                });
                            }}
                            label={t("auth.password.repeat")}
                        />
                        <input
                            className="u-full-width button-primary"
                            disabled={!enableButtons}
                            onClick={submit}
                            type="button"
                            value={t("auth.submit") as string}
                        />
                    </div>
                </Row>
            </div>
        </>
    );
};

export default Recover;
