import React from "react";

export default (props: SVGIcon) => (
    <svg
        width={String(props.size)}
        height={String(props.size)}
        version="1.1"
        viewBox={String([0, 0, 6, 6.35])}
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
    >
        <g transform="translate(0 -290.65)" fill="none">
            <g strokeWidth=".161">
                <circle cx="1.0154" cy="292.44" r=".21707" />
                <circle cx="1.4908" cy="291.73" r=".37248" />
                <circle cx="2.0755" cy="292.3" r=".21707" />
                <circle cx="2.2999" cy="291.57" r=".21707" />
            </g>
            <path
                d="m0.61954 293.07h1.2367v1.8371l1.2377-0.53647v0.53647l1.2377-0.53647v0.53647l1.2377-0.53647v2.3232h-4.9499z"
                strokeWidth=".30px"
            />
        </g>
    </svg>
);
