import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Row from "../components/Row";
import UploadFile from "../components/UploadFile";
import Authentication from "./Authentication";

const Upload = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const loggedIn = useSelector((state: ReduxState) => state.user.loggedIn);

    useEffect(() => {
        document.title = t("upload.title");
        dispatch({ type: "RESET_UPLOAD_ERROR" });
    });

    if ((loggedIn === null) || !loggedIn) {
        return <Authentication />
    }
    
    return (
        <Row classes="App">
            <div className="col-12 col-md-5 App">
                <h1>{t("upload.input")}</h1>
                <p>{t("upload.description")}</p>
                <UploadFile />
            </div>
        </Row>
    );
};

export default Upload;
