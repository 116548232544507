import { schemeBlues, schemeReds } from "d3-scale-chromatic";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Authentication from "./Authentication";
import Chart from "../charts/ChartContainer";
import GroupedBarChart from "../charts/GroupedBarChart";
import Treemap from "../charts/Treemap";
import WorldMap from "../charts/worldMap/WorldMap";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import GlobalFilters from "../components/GlobalFilters";
import InputTable from "../components/InputTable";
import ResultsTableComparison from "../components/ResultsTableComparison";
import Row from "../components/Row";
import config from "../config";
import { initializeFilters, findDataMatchingFilters, updateFilters } from "../utils/filters";
import { useMountEffect } from "../utils/useMountEffect";
import LoadingSpinner from "./LoadingSpinner";
import { filterByImpactCategory, initialFilters, } from "./Portfolio";

//TODO
// individual page
// sector page
// country page

function truncateLabel(defaultLabel: string, selectedFilters: IFilters) {
    let label = defaultLabel;
    if (selectedFilters.ClientNameCode.length === 1) {
        label = selectedFilters.ClientNameCode[0];
        if (label.length > 10) {
            label = label.substring(0, 9) + '\u2026'; // Add ellipsis
        }
    }
    return label;
}

const Comparison = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [data1, setData1] = useState<IData[]>([]);
    const [data2, setData2] = useState<IData[]>([]);
    const [filters1, setFilters1] = useState<IFilters>(initialFilters);
    const [filters2, setFilters2] = useState<IFilters>(initialFilters);
    const [selectedFilters1, setSelectedFilters1] = useState<IFilters>(initialFilters);
    const [selectedFilters2, setSelectedFilters2] = useState<IFilters>(initialFilters);

    const loggedIn = useSelector((state: ReduxState) => state.user.loggedIn);
    
    useMountEffect(() => {
        document.title = t("comparison.title");
        initializeFilters()
            .then(initFilters => {
                setFilters1(initFilters);
                const initSelectedFilters = { ...initFilters, FiscalYear: [initFilters.FiscalYear[0]] };
                setSelectedFilters1(initSelectedFilters)
                setFilters2(initFilters);
                setSelectedFilters2(initSelectedFilters)
            })
            .catch(e => console.error(e))
            .finally(() => setInitializing(false));
    });

    useEffect(() => {
        setLoading(true);
        findDataMatchingFilters(selectedFilters1)
            .then(results => setData1(results))
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }, [selectedFilters1]);

    useEffect(() => {
        setLoading(true);
        findDataMatchingFilters(selectedFilters2)
            .then(results => setData2(results))
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }, [selectedFilters2]);

    useEffect(() => {
        updateFilters(selectedFilters1)
            .then(result => {
                setFilters1(result);
            })
            .catch(e => console.error(e));
    }, [selectedFilters1]);

    useEffect(() => {
        updateFilters(selectedFilters2)
            .then(result => {
                setFilters1(result);
            })
            .catch(e => console.error(e));
    }, [selectedFilters2]);

    const impactCategory = useSelector(
        (state: ReduxState) => state.data.impactCategory,
    );

    const dataFiltered1 = useMemo(
        () => filterByImpactCategory(data1, impactCategory),
        [data1, impactCategory],
    );
    const dataFiltered2 = useMemo(
        () => filterByImpactCategory(data2, impactCategory),
        [data2, impactCategory],
    );

    if ((loggedIn === null) || !loggedIn) {
        return <Authentication />
    }

    if (initializing) {
        return <LoadingSpinner />;
    }

    let label1 = truncateLabel(t("comparison.sel1"), selectedFilters1);
    let label2 = truncateLabel(t("comparison.sel2"), selectedFilters2);

    return <>
        {loading && <LoadingSpinner />}
        <Row>
            <div className="col-6 col-sm-2 border-r all-filters">
                <h5>{t("comparison.filters1")}</h5>
                <Filters
                    filters={filters1}
                    selectedFilters={selectedFilters1}
                    setFunction={setSelectedFilters1}
                    showYear={true}
                    showSingleInvestment={true}
                    selection="sel1"
                />
            </div>
            <div className="col-6 col-sm-2 border-r all-filters">
                <h5>{t("comparison.filters2")}</h5>
                <Filters
                    filters={filters2}
                    selectedFilters={selectedFilters2}
                    setFunction={setSelectedFilters2}
                    showYear={true}
                    showSingleInvestment={true}
                    selection="sel2"
                />
            </div>
            <div className="col-12 col-sm-8 portfolio">
                <div className="row tables-row portfolio comparison-row">
                    <div className="col col-12 col-md-4 border-r input-data-table">
                        <h5>{t("portfolio.input")}</h5>
                        <InputTable
                            data={data1}
                            data2={data2}
                            attribution={filters1.attribution[0]}
                            attribution2={filters2.attribution[0]}
                            label={label1}
                            label2={label2}
                        />
                    </div>
                    <div className="col col-12 col-md-8 overflow-x-auto">
                        <h5>{t("portfolio.results")}</h5>
                        <ResultsTableComparison
                            data1={data1}
                            data2={data2}
                            label1={label1}
                            label2={label2}
                        />
                    </div>
                </div>
                <Row classes={"global-filters-row"}>
                    <GlobalFilters />
                </Row>
                <Row>
                    <div className="svg-container col-12 col-md-6">
                        <Chart
                            title="Breakdown by country"
                            download={true}
                            fileName={"JIM - Breakdown by country.svg"}
                        >
                            <WorldMap
                                data={dataFiltered1}
                                label={label1}
                                data2={dataFiltered2}
                                label2={label2}
                                multipleData={true}
                                width={590}
                                impactCategory={impactCategory}
                            />
                        </Chart>
                    </div>
                    <div className="svg-container col-12 col-md-6">
                        <Chart
                            title="Breakdown by impact type"
                            download={true}
                            fileName="JIM - Breakdown by impact type.svg"
                        >
                            <GroupedBarChart
                                data={[dataFiltered1, dataFiltered2]}
                                height={250}
                                width={500}
                                impactCategory={impactCategory}
                                labels={[label1, label2]}
                                keys={[
                                    config.subtypes.DIRECT,
                                    config.subtypes.INDIRECT,
                                    config.subtypes.INDUCED,
                                    config.typesImpact.POWER,
                                    config.typesImpact.FE,
                                ]}
                            />
                        </Chart>
                    </div>
                </Row>
                <Row>
                    <div className="svg-container col-12 col-md-6">
                        <Chart
                            title={label1 + " - " + t("comparison.breakdownBySector")}
                            download={true}
                            fileName={"JIM - " + label1 + " - Breakdown by sector.svg"}
                        >
                            <Treemap
                                data={dataFiltered1}
                                height={400}
                                width={350}
                                impactCategory={impactCategory}
                                colorScheme={schemeBlues[9]}
                            />
                        </Chart>
                    </div>
                    <div className="svg-container col-12 col-md-6">
                        <Chart
                            title={label2 + " - " + t("comparison.breakdownBySector")}
                            download={true}
                            fileName={"JIM - " + label2 + " - Breakdown by sector.svg"}
                        >
                            <Treemap
                                data={dataFiltered2}
                                height={400}
                                width={350}
                                impactCategory={impactCategory}
                                colorScheme={schemeReds[9]}
                            />
                        </Chart>
                    </div>
                </Row>
            </div>
        </Row>
        <Footer />
    </>;
};

export default Comparison;
