import React from "react";

const Info = (props: SVGIcon) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={String(props.size)}
        height={String(props.size)}
        viewBox={String([0, 0, 24, 24])}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-upload-cloud"
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
    >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
);
export default Info;
