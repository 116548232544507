import { createReducer } from "redux-starter-kit";

export const initialUserState: ReduxUserState = {
    loggedIn: null,
    unauthorized: false,
};

export const userReducer = createReducer(initialUserState, {
    LOGIN: (state: ReduxUserState) => {
        state.loggedIn = true;
        state.unauthorized = false;
    },
    LOGOUT: (state: ReduxUserState) => {
        state.loggedIn = false;
        state.unauthorized = false;
        // redirect to homepage after logout
        window.location.assign("/")
    },
    UNAUTHORIZED: (state: ReduxUserState) => {
        state.loggedIn = false;
        state.unauthorized = true;
    }
});
