import React, { Suspense } from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import "./style/index.scss";
import LoadingSpinner from "./views/LoadingSpinner";

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <Suspense fallback={<LoadingSpinner />}>
                <App />
            </Suspense>
        </Provider>
    </CookiesProvider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
