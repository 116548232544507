import isEmail from "validator/lib/isEmail";
import passwordValidator from "password-validator";

/**
 * Password requirements for validator.
 */
const schema = new passwordValidator();

schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits();

/**
 * Checks that password satisfies requirements defined in schema.
 * @param password string
 */
export const validatePassword = (password: string) => {
    const valid = schema.validate(password, { list: true });
    if (typeof valid !== "boolean")
        return {
            isValid: valid.length === 0,
            requirements: valid,
        };
    return {
        isValid: true,
        requirements: [],
    };
};

/**
 * Checks that email is a valid address.
 * @param email string
 */
export const validateEmail = (email: string) => {
    return { isValid: isEmail(email) };
};
