
import { unique } from "../utils/makeOptions";
import dbPromise from "../db";
import config from "../config";
import { getAttributionShareValue } from "../utils/reducers";
import { sectorsList } from "../charts/sectorsMap";

interface IFilter {
    key: string;
    value: string[];
}

export enum Attribution {
    No = "No attribution",
    Com = "Committed",
    Out = "Outstanding",
}

/** Applies attribution share to every GTAP sector and to the total */
const applyAttribution = (data: IData, attrType: string) => {
    const attributionShare = getAttributionShareValue(data, attrType);
    for (const sector of sectorsList) {
        data[sector] = (data[sector] as number) * attributionShare;
    }
    data.Total = data.Total * attributionShare;
    return data;
};


export const initializeFilters = async () => {
    const db = await dbPromise;
    const allValues = await db
        .transaction("investments", "readonly")
        .objectStore("investments")
        .getAll();
    return {
        ClientNameCode: ["All", ...unique(allValues, config.CLIENT_NAME)],
        // convert years to strings to allow filtering with value from input select
        ReportingYear: [...unique(allValues, config.REPORTING_YEAR).sort()],
        FiscalYear: [...unique(allValues, config.FISCAL_YEAR).sort()],
        EconomicActivity: ["All", ...unique(allValues, config.SECTOR)],
        Country: ["All", ...unique(allValues, config.COUNTRY)],
        SubRegionName: ["All", ...unique(allValues, config.SUBREGION)],
        IncomeClassification: [
            "All",
            ...unique(allValues, config.INCOME_CLASS),
        ],
        ClientType: ["All", ...unique(allValues, config.CLIENT_TYPE)],
        LeastDeveloped: ["All", ...unique(allValues, config.LEAST_DEVELOPED)],
        attribution: [Attribution.No, Attribution.Com, Attribution.Out],
    };
};

/**
* Only show the filters that have values for the filters the user already selected.
* A.K.A. Filter the filters depending on the selected filters
* @param selectedFilters
* @returns
*/
export const updateFilters = async (selectedFilters: IFilters) => {
    const db = await dbPromise;
    let data = await db
        .transaction("investments", "readonly")
        .objectStore("investments")
        .getAll();

    const attribution = ["No attribution"];
    if (data.find(a => a.AttributionShareOutstanding != null) !== undefined) {
        attribution.push(Attribution.Out);
    }

    if (data.find(a => a.AttributionShareCommitted != null) !== undefined) {
        attribution.push(Attribution.Com);
    }

    if (selectedFilters.attribution[0] === Attribution.Out) {
        data = data.filter(v => !!v.AttributionShareOutstanding);
    }
    else if (selectedFilters.attribution[0] === Attribution.Com) {
        data = data.filter(v => !!v.AttributionShareCommitted);
    }

    return {
        ClientNameCode: ["All", ...unique(data, config.CLIENT_NAME)],
        // convert years to strings to allow filtering with value from input select
        ReportingYear: [...unique(data, config.REPORTING_YEAR).sort()],
        FiscalYear: [...unique(data, config.FISCAL_YEAR).sort()],
        EconomicActivity: ["All", ...unique(data, config.SECTOR)],
        Country: ["All", ...unique(data, config.COUNTRY)],
        SubRegionName: ["All", ...unique(data, config.SUBREGION)],
        IncomeClassification: ["All", ...unique(data, config.INCOME_CLASS),],
        ClientType: ["All", ...unique(data, config.CLIENT_TYPE)],
        LeastDeveloped: ["All", ...unique(data, config.LEAST_DEVELOPED)],
        attribution: attribution,
    };
};

/**
 * Sort filters by length of value options so that easier to compute filters are run first
 */
const sortFilters = (a: IFilter, b: IFilter) => a.value.length - b.value.length;

/**
 * Returns all investments that match the passed filters,
 * if required applies attribution share to results
 *
 * @param filters
 * @param setFunction
 * @param attribution
 *
 */
export const findDataMatchingFilters = async (filters: IFilters) => {
    let filtersToUse: IFilter[] = [];
    for (const key in filters) {
        if (key === "Country" || key === "attribution") continue;
        const filter = filters[key];
        if (filter[0] === "All") continue;
        filtersToUse.push({ key: key, value: filter as string[] });
    }
    filtersToUse = filtersToUse.sort(sortFilters);

    let results: IData[] = [];
    for (const country of filters.Country) {
        const db = await dbPromise;
        let cursor = await db
            .transaction("investments", "readonly")
            .objectStore("investments")
            .index("byCountry")
            .openCursor(IDBKeyRange.only(country));
        while (cursor) {
            let exclude = false;
            for (const filter of filtersToUse) {
                const { key, value } = filter;
                if (value.indexOf(String(cursor.value[key])) < 0) {
                    exclude = true;
                    break;
                }
            }
            if (!exclude) {
                const { value } = cursor;
                if (filters.attribution[0] !== Attribution.No) {
                    results.push(applyAttribution(value, filters.attribution[0]));
                }
                else {
                    results.push(value)
                }
            }
            cursor = await cursor.continue();
        }
    }
    return results;
};